import { gql, useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import {
  ApplyGuidanceToAll,
  ApplyGuidanceToAllVariables,
  BuildEmptyMatrix,
  BuildEmptyMatrixVariables,
  DeleteGuidanceRange,
  DeleteGuidanceRangeVariables,
  DeletePerfRatingOption,
  DeletePerfRatingOptionVariables,
} from "src/__generated__/graphql";

const DELETE_PERF_RATING_OPTION = gql`
  mutation DeletePerfRatingOption($data: DeleteGuidanceItemInput!) {
    deletePerfRatingOption(data: $data)
  }
`;

export function useDeletePerfRatingOption(): (id: number) => Promise<unknown> {
  const [deletePerfRatingOption] = useMutation<
    DeletePerfRatingOption,
    DeletePerfRatingOptionVariables
  >(DELETE_PERF_RATING_OPTION);

  return useCallback(
    (id: number) => deletePerfRatingOption({ variables: { data: { id } } }),
    [deletePerfRatingOption]
  );
}

const DELETE_GUIDANCE_RANGE = gql`
  mutation DeleteGuidanceRange($data: DeleteGuidanceItemInput!) {
    deleteGuidanceRange(data: $data)
  }
`;

export function useDeleteGuidanceRange(): (id: number) => Promise<unknown> {
  const [deleteGuidanceRange] = useMutation<
    DeleteGuidanceRange,
    DeleteGuidanceRangeVariables
  >(DELETE_GUIDANCE_RANGE);

  return useCallback(
    (id: number) => deleteGuidanceRange({ variables: { data: { id } } }),
    [deleteGuidanceRange]
  );
}

const BUILD_EMPTY_MATRIX = gql`
  mutation BuildEmptyMatrix($data: BuildEmptyMatrixInput!) {
    buildEmptyMatrix(data: $data)
  }
`;

export function useBuildEmptyMatrix(
  compCycleId: number
): () => Promise<unknown> {
  const [buildEmptyMatrix] = useMutation<
    BuildEmptyMatrix,
    BuildEmptyMatrixVariables
  >(BUILD_EMPTY_MATRIX);

  return useCallback(
    () => buildEmptyMatrix({ variables: { data: { compCycleId } } }),
    [buildEmptyMatrix, compCycleId]
  );
}

const APPLY_GUIDANCE_TO_ALL = gql`
  mutation ApplyGuidanceToAll($data: ApplyGuidanceToAllInput!) {
    applyGuidanceToAll(data: $data) {
      participants {
        subjectId
        compCycleId
      }
    }
  }
`;

export function useApplyGuidanceToAll(
  compCycleId: number
): () => Promise<boolean> {
  const [applyGuidanceToAll] = useMutation<
    ApplyGuidanceToAll,
    ApplyGuidanceToAllVariables
  >(APPLY_GUIDANCE_TO_ALL);

  return useCallback(
    () =>
      applyGuidanceToAll({ variables: { data: { compCycleId } } })
        .then((res) => true)
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
          return false;
        }),
    [compCycleId, applyGuidanceToAll]
  );
}
