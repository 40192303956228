import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { Comparator, ComparatorWithOrder } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import { TableCell, TableRow, Tooltip, makeStyles } from "@material-ui/core";
import { ComponentType } from "react";
import { CellProps, Column, Row } from "react-table";
import { VirtualItem } from "react-virtual";
import {
  CompRecommendationStatus,
  CondensedTable_participant as Participant,
  CondensedTable_position as Position,
} from "src/__generated__/graphql";

import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_1, GRAY_4, GRAY_6, GRAY_7, GRAY_9, WHITE } from "src/theme";
import { CondensedTableActionsCell2 as CondensedTableActionsCell } from "../Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as CondensedTableActivityCell } from "../Cells/CondensedTableActivityCell2";
import { CondensedTableNameCell2 as CondensedTableNameCell } from "../Cells/CondensedTableNameCell2";
import { CondensedTableStatusCell2 as CondensedTableStatusCell } from "../Cells/CondensedTableStatusCell2";
import { LARGE_COL_WIDTH, XLG_COL_WIDTH } from "../Cells/dimensions";
import { useBulkActionsData } from "../Contexts/BulkActionsContext";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
  ordering: (data: {
    currencies: Map<CurrencyCode, Currency>;
    defaultCurrencyCode: CurrencyCode;
    defaultCurrency: Currency;
    availablePositions: Position[];
    workingHoursPerYear: number | undefined;
  }) => Comparator<Participant> | ComparatorWithOrder<Participant>;
};

const useStyles = makeStyles((theme) => ({
  stickyCell: {
    position: "sticky",
    zIndex: 2,
    background: WHITE,
  },
  row: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 48,
    "&:hover": {
      "& $tableBodyCell": {
        backgroundColor: `${GRAY_9} !important`,
      },
    },
    userSelect: "none" /* Standard syntax */,
    webkitUserSelect: "none" /* Safari */,
    msUserSelect: "none" /* IE 10 and IE 11 */,
  },
  tableBodyCell: {
    boxSizing: "border-box",
    boxShadow: `inset -1px 0px 0px ${GRAY_6}`,
    color: GRAY_4,
    fontWeight: 450,
    fontSize: "13px",
    padding: theme.spacing(0, 1.5),
    lineHeight: "130%",
    alignItems: "center",
    height: "48px",
    letterSpacing: "-0.5px",
  },
  nameCell: {
    left: 0,
  },
  actionCell: {
    position: "sticky",
    left: XLG_COL_WIDTH, // account for the name column
  },
  statusCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
    boxShadow: "3px 0px 6px 0px rgba(10, 36, 64, 0.10)",
  },
  activityCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
  },
  lastDefaultColumn: {
    boxShadow: "none",
  },
  rowNeedsAction: { color: GRAY_1 },
  rowActedOn: {
    color: GRAY_4,
    background: GRAY_7,
  },
  rowSelected: {
    background: GRAY_6,
  },
  tooltip: {
    height: "30px",
    zIndex: 0,
  },
  tooltipTextContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignText: "center",
  },
  tooltipCmdText: {
    border: `1px solid ${GRAY_4}`,
    borderRadius: "4px",
    padding: theme.spacing(0, 0.5),
  },
}));

type Props = {
  virtualRow: VirtualItem;
  rows: Row<Participant>[];
  prepareRow: (row: Row<Participant>) => void;
};

export function CondensedTableRow({
  virtualRow,
  rows,
  prepareRow,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    selectedParticipants,
    setSelectedParticipants,
    lastSelectedRowID,
    isFeatureEnabled,
  } = useBulkActionsData();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: string,
    subjectId: number,
    rows: Row<Participant>[]
  ) => {
    if (isFeatureEnabled) {
      // hot key for multiple employee selection
      if (event.shiftKey) {
        const empIDRange = getRowRange(
          rows,
          Number(rowId),
          Number(lastSelectedRowID ?? 0)
        );
        setSelectedParticipants(empIDRange, rowId);
      } else {
        // single employee selection
        setSelectedParticipants([subjectId], rowId);
      }
    }
  };

  const row = rows[virtualRow.index];
  prepareRow(row);

  const reviewStatus = row.original.compRecommendation?.reviewStatus ?? null;

  const actionNeededStatuses = [
    CompRecommendationStatus.FUTURE_PHASE,
    CompRecommendationStatus.NEEDS_REVIEW,
    CompRecommendationStatus.LATE,
  ];

  const participantSelected =
    selectedParticipants.get(row.original.subject.id)?.isSelected ?? false;

  const rowNeedsAction =
    reviewStatus == null || actionNeededStatuses.includes(reviewStatus);
  return (
    <Tooltip
      className={classes.tooltip}
      placement="top-start"
      title={
        isFeatureEnabled && participantSelected ? (
          <div className={classes.tooltipTextContainer}>
            <AssembleTypography variant="productTooltip">
              Use
            </AssembleTypography>
            <AssembleTypography
              variant="productTooltip"
              textColor={GRAY_4}
              className={classes.tooltipCmdText}
            >
              Shift
            </AssembleTypography>
            <AssembleTypography variant="productTooltip">
              to select multiple people
            </AssembleTypography>
          </div>
        ) : (
          ""
        )
      }
    >
      <TableRow
        {...row.getRowProps()}
        key={row.getRowProps().key}
        className={classes.row}
        onClick={(e) => onRowClick(e, row.id, row.original.subject.id, rows)}
        style={{
          height: `${virtualRow.size}px`,
          transform: `translateY(${virtualRow.start}px)`,
        }}
      >
        {row.cells.map((cell, index) => {
          return (
            <TableCell
              {...cell.getCellProps({
                style: {
                  maxWidth: cell.column.width,
                  width: cell.column.width,
                  minWidth: cell.column.width,
                },
              })}
              key={cell.getCellProps().key}
              className={cx(classes.tableBodyCell, {
                [classes.stickyCell]:
                  cell.column.id === CondensedTableNameCell.id ||
                  cell.column.id === CondensedTableActionsCell.id ||
                  cell.column.id === CondensedTableStatusCell.id ||
                  cell.column.id === CondensedTableActivityCell.id,

                [classes.nameCell]:
                  cell.column.id === CondensedTableNameCell.id,
                [classes.actionCell]:
                  cell.column.id === CondensedTableActionsCell.id,
                [classes.statusCell]:
                  cell.column.id === CondensedTableStatusCell.id,
                [classes.activityCell]:
                  cell.column.id === CondensedTableActivityCell.id,

                [classes.lastDefaultColumn]: index === row.cells.length,
                [classes.rowNeedsAction]: rowNeedsAction,
                [classes.rowActedOn]: !rowNeedsAction,
                [classes.rowSelected]: participantSelected,
              })}
            >
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    </Tooltip>
  );
}

const getRowRange = (
  rows: Row<Participant>[],
  currentID: number,
  selectedID: number
): number[] => {
  const rangeStart = selectedID > currentID ? currentID : selectedID;
  const rangeEnd = rangeStart === currentID ? selectedID : currentID;
  return rows
    .slice(rangeStart, rangeEnd + 1)
    .map((row) => row.original.subject.id);
};
