import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  CondensedTable_matrix,
  CondensedTableSalaryMeritGuidanceCell2_participant as Participant,
} from "src/__generated__/graphql";
import { getGuidance } from "src/models/MeritGuidance";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritGuidanceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const tableData = useTableData();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const meritMatrix = tableData?.matrices?.find(
    (matrix) => matrix.type === "MERIT"
  );

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidance = getGuidance(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  return (
    <div>
      {guidance != null ? (
        <AssembleTruncatedTypography align="right">
          {guidance === 0
            ? "-"
            : formatNumeral(guidance, {
                style: "percent",
                maximumFractionDigits: 2,
              })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableSalaryMeritGuidanceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritGuidanceCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        allSubmittedItems {
          id
        }
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }

      perfRating
    }
  `,
};

CondensedTableSalaryMeritGuidanceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE
);
CondensedTableSalaryMeritGuidanceCell2.id = ColumnIds.SALARY_MERIT_GUIDANCE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritGuidanceCell2,
  Header: CondensedTableSalaryMeritGuidanceCell2.Header,
  id: CondensedTableSalaryMeritGuidanceCell2.id,
  defaultCanSort: false,
};

CondensedTableSalaryMeritGuidanceCell2.column = column;
CondensedTableSalaryMeritGuidanceCell2.ordering = ({
  matrices,
}: {
  matrices: CondensedTable_matrix[];
}) =>
  contramap((employee: Participant) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const meritMatrix = matrices?.find((matrix) => matrix.type === "MERIT");

    const matrixGuides =
      meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
        ...matrixGuide,
        perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
        matrixRangeId: matrixGuide.matrixRange.id,
      })) ?? [];

    const perfRatingOptions = uniqBy(
      matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
      "name"
    );

    const matrixRanges = uniqBy(
      matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
      "rangeStart"
    );

    return (
      getGuidance(
        {
          ...employee.subject,
          perfRating: employee.perfRating,
        },
        matrixGuides,
        matrixRanges,
        perfRatingOptions
      ) ?? 0
    );
  });
