import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { compareAgainstBand } from "@asmbl/shared/money";
import {
  EmployeeBanners_employee as Employee,
  Noun,
} from "../../../__generated__/graphql";
import { AccessBoundary } from "../../../components/AccessBoundary";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { DismissableInfoBanner } from "../../../components/InfoBanner";
import { needsLeveling } from "../../../models/Employee";

interface Props {
  employee: Employee;
}

export function EmployeeBanners({ employee }: Props): JSX.Element {
  const { activeCashCompensation } = employee;

  const salary = getSalaryCashComp(activeCashCompensation);
  const bandComparison = compareAgainstBand(salary);
  const isBelowBand = (bandComparison ?? 0) < 0;
  const isAboveBand = (bandComparison ?? 0) > 0;

  return (
    <>
      {needsLeveling(employee) && (
        <DismissableInfoBanner level="warning">
          <AccessBoundary
            scope="global"
            verb="edit"
            noun={Noun.Employee}
            fallback={
              <AssembleTypography variant="productSmallBold">
                {employee.displayName} isn't leveled. To see where{" "}
                {employee.displayName} falls in their band, contact your
                compensation manager to level {employee.displayName}.
              </AssembleTypography>
            }
          >
            <AssembleTypography variant="productSmallBold">
              {employee.displayName} isn't leveled. To see where{" "}
              {employee.displayName} falls in their band, map{" "}
              {employee.displayName}'s HRIS job title to an Assemble Position.
            </AssembleTypography>
          </AccessBoundary>
        </DismissableInfoBanner>
      )}
      {isBelowBand && (
        <DismissableInfoBanner level="warning">
          <AssembleTypography variant="productSmallBold">
            {employee.displayName} is BELOW BAND.
          </AssembleTypography>
        </DismissableInfoBanner>
      )}
      {isAboveBand && (
        <DismissableInfoBanner level="warning">
          <AssembleTypography variant="productSmallBold">
            {employee.displayName} is ABOVE BAND.
          </AssembleTypography>
        </DismissableInfoBanner>
      )}
    </>
  );
}

EmployeeBanners.fragments = {
  employee: gql`
    fragment EmployeeBanners_employee on Employee {
      id
      displayName
      employmentStatus
      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        adjustedCashBand {
          id
          currencyCode
          bandPoints {
            id
            annualCashEquivalent
          }
        }
      }
      activeEmployment {
        id
        positionId
      }
    }
  `,
};
