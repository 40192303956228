import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { mapMaybe } from "@asmbl/shared/utils";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import { enqueueSnackbar } from "notistack";
import { useIntercom } from "react-use-intercom";
import {
  CompUnit,
  CondensedTable_matrix as Matrix,
  BulkActionsBar_participant as Participant,
  RecItemInput,
  RecItemType,
  RecommendationReviewStatus,
} from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { useAuth } from "src/components/Auth/AuthContext";
import { getGuidance } from "src/models/MeritGuidance";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  useEmplaceCompRecommendations,
  useSubmitRecReviews,
} from "src/mutations/CompRecommendation";
import { formatError } from "src/pages/Authentication/Authentication";
import { useBulkActionsData } from "src/pages/CompCycle/Plan/Contexts/BulkActionsContext";
import { useTableData } from "src/pages/CompCycle/Plan/Contexts/TableDataContext2";
import { GRAY_3, GRAY_4, GRAY_6, PURPLE_1, WHITE } from "../../../theme";
import { AssembleBarModal } from "../../AssembleBarModal";
import { TargetIcon } from "../../AssembleIcons/Small/TargetIcon";
import { ThumbsUpIcon } from "../../AssembleIcons/Small/ThumbsUpIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    top: "auto",
    backgroundColor: "rgba(255, 0, 0, 0)",
    boxShadow: "none",
    left: theme.spacing(10.125), // account for navbar and border
    overflow: "hidden",
  },
  tabContainer: {
    display: "flex",
    overflow: "hidden",
    paddingTop: "5px",
    maxHeight: "2rem",
  },
  tab: {
    backgroundColor: WHITE,
    minWidth: "7.75rem",
    height: "2.5rem",
    boxShadow: `0px 0px 0px 1px ${GRAY_6}`,
    borderRadius: "0px 5px 0px 0px",
    zIndex: 1,
    justifySelf: "flex-start",
  },
  tabButton: {
    padding: theme.spacing(0, 0, 0, 1.5),
  },
  tabText: {
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "140%",
    "&:hover": {
      color: PURPLE_1,
    },
  },
  rotatedIcon: {
    transform: "rotate(180deg)",
  },
  barContainer: {
    display: "flex",
    flexDirection: "row",
    boxShadow: `0 -5px 15px -5px rgba(10,36,64,.1)`,
  },
  rightBuffer: {
    display: "flex",
    flexDirection: "column",
    width: "17%",
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    backgroundColor: WHITE,
    overflow: "hidden",
  },
  barModal: {
    width: "310px",
    height: "54px",
  },
}));

export const BulkActionsBar = (): JSX.Element => {
  const classes = useStyles();
  const { compCycleId, selectedCurrency, matrices } = useTableData();
  const {
    getSelectedParticipants,
    clearSelectedParticipants,
    selectedCount,
    selectOrClearAll,
  } = useBulkActionsData();
  const intercom = useIntercom();
  const urlSearchParams = useURLSearchParams();
  const { permissions } = useAuth();
  const { trackEvent } = useTrack();
  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;
  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;

  const emplaceCompRecommendations = useEmplaceCompRecommendations(
    compCycleId,
    currencyCode,
    actingManagerEmployeeId
  );

  const submitCompRecReview = useSubmitRecReviews(currencyCode);

  const handleGuidanceOnClick = async () => {
    try {
      const guidance = mapMaybe(
        getSelectedParticipants().map((participant) =>
          generateGuidance(participant, matrices)
        ),
        (guidance) => guidance
      );

      if (guidance.length === 0) {
        enqueueSnackbar(
          "No guidance to submit; guidance has either already been submitted or there is no guidance to apply.",
          { variant: "info" }
        );
        clearSelectedParticipants();
        return;
      }

      const compRecs = await emplaceCompRecommendations(guidance);
      if (
        compRecs &&
        compRecs.emplaceCompRecommendations.participants.length > 0
      ) {
        clearSelectedParticipants();
        trackEvent({
          object: "Change Requests",
          subArea: "Guidance Button",
          action: "Submitted",
          compCycleId,
        });
        enqueueSnackbar("Guidance submitted.", { variant: "success" });
      } else {
        enqueueSnackbar("No guidance was submitted. Please try again.", {
          variant: "default",
        });
      }
    } catch (e) {
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  const handleApproveOnClick = async () => {
    const compRecs = mapMaybe(getSelectedParticipants(), (participant) =>
      participant.compRecommendation &&
      participant.compRecommendation.latestSubmittedItems.length > 0
        ? participant
        : null
    );
    if (compRecs.length === 0) {
      enqueueSnackbar(
        "No reviews to submit. Make sure recommendations have changes to approve.",
        { variant: "info" }
      );
      return;
    }
    try {
      const updatedRecs = await submitCompRecReview({
        compCycleId,
        actingManagerEmployeeId,
        data: compRecs.map((rec) => ({
          subjectId: rec.subjectId,
          compCycleId,
          status: RecommendationReviewStatus.APPROVED,
        })),
      });
      if (updatedRecs != null) {
        clearSelectedParticipants();
        trackEvent({
          object: "Change Requests",
          subArea: "Bulk Actions Bar",
          action: "Submitted",
          compCycleId,
        });

        intercom.trackEvent("Comp Recommendations Submitted");
        enqueueSnackbar("Your reviews have been submitted.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("No reviews were submitted. Please try again.", {
          variant: "default",
        });
      }
    } catch (e) {
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  return (
    <AssembleBarModal
      selectedCount={selectedCount}
      className={classes.barModal}
      iconOnClick={selectOrClearAll}
    >
      <div>
        <Tooltip title="Accept Requests" placement="top" arrow>
          <IconButton onClick={handleApproveOnClick}>
            <ThumbsUpIcon color={GRAY_3} width={16} height={16} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Apply Merit Guidance" placement="top" arrow>
          <IconButton onClick={handleGuidanceOnClick}>
            <TargetIcon color={GRAY_3} width={16} height={16} />
          </IconButton>
        </Tooltip>
      </div>
    </AssembleBarModal>
  );
};

BulkActionsBar.fragments = {
  participant: gql`
    fragment BulkActionsBar_participant on CompCycleParticipant {
      subjectId
      compCycleId
      perfRating
      subject {
        id
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          submittedAt
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          recommendedTitle
          recommendedPosition {
            id
          }
          note
          unitType
        }
      }
    }
  `,
};

function generateGuidance(participant: Participant, matrices: Matrix[]) {
  const { compRecommendation } = participant;

  const basePay = getSalaryCashComp(participant.subject.activeCashCompensation);

  if (basePay == null) return;

  const meritMatrix = matrices.find((matrix) => matrix.type === "MERIT");

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidance = getGuidance(
    {
      ...participant,
      ...participant.subject,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  if (guidance == null) return;

  const guidanceItem = compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );

  // guidance already auto-applied, do not apply again
  if (guidanceItem && guidanceItem.recommendedPercentValue === guidance * 100)
    return;

  const latestItems = compRecommendation
    ? compRecommendation.latestSubmittedItems.filter(
        (item) => item.recommendationType !== RecItemType.MERIT_INCREASE
      )
    : [];

  const newItems: RecItemInput[] = [
    ...latestItems.map((item) => ({
      recommendationType: item.recommendationType,
      note: item.note,
      recommendedPercentValue: item.recommendedPercentValue,
      recommendedCashValue: item.recommendedCashValue,
      recommendedTitle: item.recommendedTitle,
      recommendedPositionId: item.recommendedPosition?.id,
      unitType: item.unitType,
    })),
    {
      recommendationType: RecItemType.MERIT_INCREASE,
      note: "Applied from guidance suggestion",
      recommendedPercentValue: guidance * 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    },
  ];

  return {
    items: newItems,
    subjectId: participant.subjectId,
  };
}
