import { gql } from "@apollo/client";
import { getTenure } from "@asmbl/shared/employee";
import { formatCurrency, Money } from "@asmbl/shared/money";
import { formatElapsedTime } from "@asmbl/shared/time";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useCompStructure } from "src/components/CompStructureContext";
import { PerfRatingCell } from "src/components/Table/PerfRatingCell";
import {
  CompCycleSingleRec_matrixPerfRatingOption,
  PersonRowHeaders_participant,
  PersonRowHeaders_position,
  RecItemType,
} from "../../../__generated__/graphql";
import {
  getTotalCash,
  getTotalCashAfterRecommendation,
} from "../../../models/CashCompensation";
import { CompRecommendation } from "../../../models/CompRecommendation";
import {
  getDepartmentName,
  getLadderName,
  getPositionName,
} from "../../../models/Employee";
import { HeaderConfig } from "../../../models/HeaderConfig";
import { calculateUnitCount } from "../../../models/Money";
import { BadgedUserAvatar } from "../../../pages/People/BadgedUserAvatar";
import { AssembleTruncatedTypography } from "../../AssembleTruncatedTypography";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { BandPlacementCell } from "../../Table/BandPlacementCell";
import { DepartmentAndLadderCell } from "../../Table/DepartmentAndLadderCell";
import { EquityDisplayCell } from "../../Table/EquityDisplayCell";
import { NameAndPositionCell } from "../../Table/NameAndPositionCell";
import { AvatarCell } from "../Cells/AvatarCell";

type Props = {
  employee: PersonRowHeaders_participant;
  draftRecommendation: CompRecommendation | null;
  sharePrice: Money;
  promotedPosition: PersonRowHeaders_position | null;
  headerConfig: HeaderConfig;
  valuation: Money;
  perfRatingOptions: CompCycleSingleRec_matrixPerfRatingOption[];
  setRevisedPerfRating: (perfRating: string) => unknown;
};

const useStyles = makeStyles(() => ({
  personRow: {
    borderBottom: "none",
    border: "none",
  },
  icon: {
    transition: "transform 350ms",
  },
  newCompTextRow: {
    marginTop: "0.625rem",
    fontWeight: 500,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function PersonRowHeaders({
  employee,
  draftRecommendation: draftRec,
  sharePrice,
  promotedPosition,
  headerConfig,
  valuation,
  perfRatingOptions,
  setRevisedPerfRating,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies, defaultCurrency } = useCurrencies();
  const { compStructure } = useCompStructure();
  const { subject } = employee;
  const tenure = getTenure(subject);
  const currentTCC = getTotalCash(subject.activeCashCompensation)?.annualTotal;
  const newTCC =
    draftRec === null
      ? currentTCC
      : getTotalCashAfterRecommendation(
          defaultCurrency.code,
          subject.activeCashCompensation,
          Array.from(draftRec.items.values()),
          compStructure?.workingHoursPerYear
        )?.annualTotal;

  const oneTimeBonus = draftRec?.items.get(
    RecItemType.MERIT_BONUS
  )?.recommendedCashValue;

  const newEquity = draftRec?.items.get(
    RecItemType.EQUITY_GRANT
  )?.recommendedCashValue;
  const equityCurrency =
    (newEquity && currencies.get(newEquity.currency)) ??
    currencies.get(valuation.currency) ??
    defaultCurrency;

  return (
    <TableRow aria-level={1} className={classes.personRow}>
      <AvatarCell
        displayName={subject.displayName}
        photoURL={subject.user?.photoURL}
      />
      <NameAndPositionCell
        displayName={subject.displayName}
        position={getPositionName(subject)}
        payPeriodType={subject.activeEmployment?.payPeriod}
        employeeId={employee.subjectId}
      />
      <DepartmentAndLadderCell
        department={getDepartmentName(subject)}
        ladder={getLadderName(subject)}
      />
      <TableCell role="gridcell" align="center">
        <AssembleTruncatedTypography>
          {subject.activeEmployment?.position?.level ?? "-"}
        </AssembleTruncatedTypography>
      </TableCell>
      <PerfRatingCell
        perfRating={employee.perfRating ?? ""}
        perfRatingOptions={perfRatingOptions}
        setRevisedPerfRating={setRevisedPerfRating}
      />
      <TableCell role="gridcell">
        <AssembleTruncatedTypography>
          {tenure !== undefined
            ? formatElapsedTime(tenure, { short: true })
            : "-"}
        </AssembleTruncatedTypography>
      </TableCell>
      <TableCell role="gridcell">
        <AssembleTruncatedTypography>
          {subject.location?.name ?? "-"}
        </AssembleTruncatedTypography>
      </TableCell>
      <TableCell role="gridcell" aria-label="Employee Manager" align="center">
        {subject.minimalManager === null ? (
          "-"
        ) : (
          <BadgedUserAvatar
            displayName={subject.minimalManager.displayName}
            photoURL={subject.minimalManager.user?.photoURL ?? null}
            showTooltip
            grayscale
          />
        )}
      </TableCell>
      <TableCell role="gridcell">
        <div>
          <AssembleTypography variant="productSmaller" align="right">
            {currentTCC
              ? formatCurrency(currentTCC, { maximumFractionDigits: 0 })
              : "-"}
            {newTCC && (
              <div className={classes.newCompTextRow}>
                {formatCurrency(newTCC, { maximumFractionDigits: 0 })}
              </div>
            )}
          </AssembleTypography>
        </div>
      </TableCell>
      <BandPlacementCell
        employee={subject}
        draftRecommendation={draftRec}
        promotedPosition={promotedPosition}
        alignRight
      />
      <TableCell role="gridcell">
        {headerConfig["One-Time Bonus"].isVisible && (
          <AssembleTypography variant="productSmaller" align="right">
            {oneTimeBonus
              ? formatCurrency(oneTimeBonus, { maximumFractionDigits: 0 })
              : "-"}
          </AssembleTypography>
        )}
      </TableCell>
      <TableCell role="gridcell">
        {headerConfig["New Equity"].isVisible && (
          <AssembleTypography variant="productSmaller" align="right">
            {newEquity ? (
              <>
                <div>
                  {formatCurrency(newEquity, { maximumFractionDigits: 0 })}
                </div>
                <div>
                  {calculateUnitCount(
                    defaultCurrency,
                    sharePrice,
                    newEquity,
                    equityCurrency
                  ).toLocaleString()}{" "}
                  units
                </div>
              </>
            ) : (
              "-"
            )}
          </AssembleTypography>
        )}
      </TableCell>
      <TableCell role="gridcell" />
    </TableRow>
  );
}

PersonRowHeaders.fragments = {
  participant: gql`
    ${EquityDisplayCell.fragments.equityHoldings}
    ${BandPlacementCell.fragments.employee}
    fragment PersonRowHeaders_participant on CompCycleParticipant {
      subjectId
      compCycleId
      perfRating
      subject {
        id
        activeAt
        displayName
        user {
          id
          photoURL
        }
        minimalManager {
          id
          displayName
          user {
            id
            photoURL
          }
        }
        activeEmployment {
          id
          payPeriod
          position {
            id
            name
            level
            type
            ladder {
              id
              name
              department {
                id
                name
              }
            }
          }
        }
        equityHoldings {
          id
          ...EquityDisplayCell_EquityHoldings
        }
        location {
          id
          name
        }

        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          activeAt
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
          unit
        }
        ...BandPlacementCell_employee
      }
    }
  `,
  position: gql`
    ${BandPlacementCell.fragments.position}
    fragment PersonRowHeaders_position on Position {
      ...BandPlacementCell_position
    }
  `,
};
