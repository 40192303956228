import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ManagersTable_manager } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DV_BLUE, GRAY_1, GRAY_3, GRAY_6 } from "src/theme";
import { CompletionStatus } from "./CompletionStatus";
import { reviewStats } from "./ManagersTable";

type Props = {
  manager: ManagersTable_manager;
  rowSpan?: number;
  isPastPhase: boolean;
  setHoveredEmployeeId: (id: number | null) => unknown;
  isHighlighted: boolean;
  reviewStats: reviewStats;
};

const useStyles = makeStyles({
  container: {
    width: 250,
    height: ({ rowSpan }: { rowSpan: number }) => 56 * rowSpan,
    border: `solid 1px ${GRAY_6}`,
    padding: `11px 16px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  highlighted: {
    borderColor: DV_BLUE,
    backgroundColor: "#34AAFF0F",
  },
  textClip: {
    maxWidth: "195px",
  },
});

export function ManagerCell({
  manager,
  rowSpan = 1,
  setHoveredEmployeeId,
  isHighlighted,
  reviewStats,
  isPastPhase,
}: Props) {
  const classes = useStyles({ rowSpan });
  return (
    <div
      className={clsx(classes.container, {
        [classes.highlighted]: isHighlighted,
      })}
      onMouseEnter={() => setHoveredEmployeeId(manager.id)}
      onMouseLeave={() => setHoveredEmployeeId(null)}
    >
      <div>
        <AssembleTypography
          variant="productParagraphSmall"
          textColor={GRAY_1}
          noWrap
          className={classes.textClip}
        >
          {manager.displayName}
        </AssembleTypography>
        <AssembleTypography
          variant="productExtraSmall"
          textColor={GRAY_3}
          noWrap
          className={classes.textClip}
        >
          {manager.activeEmployment?.jobTitle}
        </AssembleTypography>
      </div>
      <div>
        <AssembleTypography
          variant="productParagraphSmall"
          textColor={GRAY_1}
          noWrap
        >
          <CompletionStatus
            requested={reviewStats[manager.id][0]}
            completed={reviewStats[manager.id][1]}
            isSkipped={reviewStats[manager.id][2]}
            isPastPhase={isPastPhase}
          />
        </AssembleTypography>
      </div>
    </div>
  );
}
