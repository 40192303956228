import { gql } from "@apollo/client";
import {
  getSalaryCashComp,
  isHourlyComp,
  isHourlyType,
} from "@asmbl/shared/compensation";
import { CurrencyCode } from "@asmbl/shared/constants";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { CashCompensation } from "src/models/Employee";
import {
  CashRows_compCycle,
  CashRows_employee,
  CashRows_position,
  CashRows_promotedPosition,
  RecItemInput,
  RecItemType,
} from "../../../__generated__/graphql";
import { BudgetType, CompSubComponentDisplay } from "../../../models/Budget";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";
import { NewSalaryRow } from "./LineItemRows/NewSalary";
import { PositionRow } from "./LineItemRows/Position";
import { SalaryIncreaseRow } from "./LineItemRows/SalaryIncrease";

type Props = {
  show: boolean;
  employee: CashRows_employee;
  positions: CashRows_position[];
  promotedPosition: CashRows_promotedPosition | null;
  compCycle: CashRows_compCycle;
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  guidance?: number;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function CashRows({
  show,
  employee,
  positions,
  promotedPosition,
  compCycle,
  submittedRecommendation,
  revisedRecommendation,
  onChangeRecommendationItem,
  guidance,
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly = Boolean(
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees
  );
  const currentPay = getSalaryCashComp(employee.activeCashCompensation) ?? null;
  const activeEmploymentSalaryCurrency = currentPay?.annualCashEquivalent
    .currency as CurrencyCode | null;

  const showRecItemAsHourly =
    showHourly && isHourlyInput(promotedPosition, currentPay);

  return (
    <>
      <HeaderSeparatorRow
        text={
          showRecItemAsHourly ? BudgetType.HOURLY_SALARY : BudgetType.SALARY
        }
        filtered={!show}
        first
      />
      {show && (
        <>
          {compCycle.allowSalaryPromotion && (
            <PositionRow
              employee={employee}
              positions={positions}
              promotedPosition={promotedPosition}
              basePay={currentPay}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              onChangeRecommendationItem={onChangeRecommendationItem}
            />
          )}
          {compCycle.allowSalaryMarket && (
            <SalaryIncreaseRow
              employee={employee}
              subComponent="salaryMarket"
              label={CompSubComponentDisplay.salaryMarket}
              basePay={currentPay}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              recommendationType={RecItemType.MARKET}
              onChangeRecommendationItem={onChangeRecommendationItem}
              isHourly={showRecItemAsHourly}
            />
          )}
          {compCycle.allowSalaryMerit && (
            <SalaryIncreaseRow
              employee={employee}
              subComponent="salaryMerit"
              label={CompSubComponentDisplay.salaryMerit}
              basePay={currentPay}
              payCurrencyCode={activeEmploymentSalaryCurrency}
              revisedRecommendation={revisedRecommendation}
              submittedRecommendation={submittedRecommendation}
              recommendationType={RecItemType.MERIT_INCREASE}
              onChangeRecommendationItem={onChangeRecommendationItem}
              guidance={guidance}
              isHourly={showRecItemAsHourly}
            />
          )}
          <NewSalaryRow
            employee={employee}
            revisedRecommendation={revisedRecommendation}
            promotedPosition={promotedPosition}
          />
        </>
      )}
    </>
  );
}

CashRows.fragments = {
  employee: gql`
    ${SalaryIncreaseRow.fragments.employee}
    ${NewSalaryRow.fragments.employee}
    ${PositionRow.fragments.employee}
    fragment CashRows_employee on Employee2 {
      ...SalaryIncreaseRow_employee
      ...NewSalaryRow_employee
      ...PositionRow_employee
      id
      activeCashCompensation(currencyCode: $currencyCode) {
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
    }
  `,
  position: gql`
    ${PositionRow.fragments.position}
    fragment CashRows_position on Position {
      id
      ...PositionRow_position
    }
  `,
  promotedPosition: gql`
    ${NewSalaryRow.fragments.position}
    fragment CashRows_promotedPosition on Position {
      id
      ...NewSalaryRow_position
    }
  `,
  compCycle: gql`
    fragment CashRows_compCycle on CompCycle2 {
      id
      allowSalary
      allowSalaryMerit
      allowSalaryMarket
      allowSalaryPromotion
      allowBonus
    }
  `,
};

function isHourlyInput(
  promotedPosition: CashRows_promotedPosition | null,
  currentPay: CashCompensation | null
) {
  if (promotedPosition) {
    return isHourlyType(promotedPosition.type);
  }

  return isHourlyComp(currentPay?.unit);
}
