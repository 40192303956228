import { isHourlyType } from "@asmbl/shared/compensation";
import {
  CashBandType,
  MONTHS_IN_A_YEAR,
  ScheduleType,
} from "@asmbl/shared/constants";
import {
  Currency,
  exchangeCurrency,
  exchangeFromTo,
} from "@asmbl/shared/currency";
import {
  Money,
  add,
  annualGrossEquityValue,
  divide,
  estimatedAnnualGrossEquityValue,
  estimatedPrice,
  formatCurrency,
  isZero,
  money,
  multiply,
  totalGrossEquityValue,
  zero,
} from "@asmbl/shared/money";
import { ReactElement, useMemo } from "react";
import {
  CurrencyCode,
  EquityGrantMethod,
  GetOffer,
} from "../../__generated__/graphql";
import { useCurrencies } from "../../components/CurrenciesContext";
import {
  CASH_COMP_COMPONENTS,
  CashBandName,
  EquityBandName,
} from "../../constants";
import { BenefitsPackage } from "../../models/Benefits";
import { NonNull, bandComparator, splitSentenceIntoLines } from "../../utils";
import { Benefits } from "./Benefits";
import Breakdown from "./Breakdown";
import CompanyInfo from "./CompanyInfo";
import Cumulative from "./Cumulative";
import Frame from "./Frame";
import { FrameProps } from "./Frame/Frame";
import Offer from "./Offer";
import { PDFPrintableLayout } from "./PDFPrintableLayout";
import TextContent from "./TextContent";

type CompStructure = NonNull<GetOffer["compStructure"]>;
type Offer = GetOffer["offer"];
type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type Valuation = NonNull<GetOffer["valuation"]>;

interface Props {
  offer: Offer;
  offerConfig: OfferConfig;
  valuation: Valuation;
  compStructure: CompStructure;
}

export function OfferFrames({
  offer,
  offerConfig,
  valuation,
  compStructure,
}: Props): JSX.Element {
  const { currencies, defaultCurrency: valuationCurrency } = useCurrencies();

  const localCurrency = useMemo(() => {
    const cashBands = offer.cash.data;
    const code = cashBands[0]?.money.currency;

    return currencies.get(code) ?? valuationCurrency;
  }, [currencies, offer.cash.data, valuationCurrency]);

  const equityCurrency = useMemo(() => {
    const equityBands = offer.equity.data;
    const code = equityBands[0]?.money?.currency;
    const equityCurr = code != null ? currencies.get(code) : code;
    return equityCurr ?? valuationCurrency;
  }, [currencies, offer.equity.data, valuationCurrency]);

  const projections = offerConfig.exitOutcomes.map((exitOutcome) =>
    money(exitOutcome, valuation.valuation.currency)
  );

  const compData = useMemo(
    () =>
      compBreakdown(
        valuationCurrency,
        localCurrency,
        equityCurrency,
        offer,
        valuation,
        compStructure,
        projections
      ),
    [
      compStructure,
      equityCurrency,
      localCurrency,
      offer,
      projections,
      valuation,
      valuationCurrency,
    ]
  );

  const illustrativeOutcomeFrames = getIllustrativeOutcomeFrames({
    offer,
    offerConfig,
    valuation,
    compStructure,
    compData,
    projections,
    localCurrency,
    equityCurrency,
  });

  const benefitsFrames = getBenefitsFrames({
    benefitsPackage: offer.benefitsPackage,
    hasAnyValue: Boolean(
      offer.benefitsPackage?.totalValue &&
        !isZero(offer.benefitsPackage.totalValue)
    ),
    offer,
    offerConfig,
  });

  return (
    <PDFPrintableLayout
      candidateName={offer.candidate.candidateName}
      offerConfig={offerConfig}
    >
      {[
        <Frame
          key="welcome"
          background
          date
          offer={offer}
          offerConfig={offerConfig}
        >
          <TextContent
            candidateName={offer.candidate.candidateName}
            header={`Welcome to ${offer.organization.name},`}
            message={offer.message}
          />
        </Frame>,
        <Frame key="companyInfo" date offer={offer} offerConfig={offerConfig}>
          <CompanyInfo
            organization={offer.organization}
            offerConfig={offerConfig}
            valuation={valuation}
            compStructure={compStructure}
          />
        </Frame>,
        <Frame key="offer" date offer={offer} offerConfig={offerConfig}>
          <Offer
            data={compData}
            offer={offer}
            valuation={valuation}
            compStructure={compStructure}
            config={offerConfig}
            localCurrency={localCurrency}
            equityCurrency={equityCurrency}
          />
        </Frame>,
        ...illustrativeOutcomeFrames,
        ...benefitsFrames,
        <Frame
          key="closing"
          background
          date
          offer={offer}
          offerConfig={offerConfig}
        >
          <TextContent
            candidateName={offer.candidate.candidateName}
            header="Thank you,"
            message={offer.closingMessage}
          />
        </Frame>,
      ]}
    </PDFPrintableLayout>
  );
}

function getIllustrativeOutcomeFrames({
  offer,
  offerConfig,
  valuation,
  compStructure,
  compData,
  projections,
  localCurrency,
  equityCurrency,
}: {
  offer: Offer;
  offerConfig: OfferConfig;
  valuation: Valuation;
  compStructure: CompStructure;
  compData: CompBreakdown;
  projections: Money[];
  localCurrency: Currency<CurrencyCode>;
  equityCurrency: Currency<CurrencyCode>;
}) {
  const hasEquity = compData.equity !== undefined && compData.equity.units > 0;

  if (projections.length < 2 || !hasEquity) return [];

  const xAxisAnnotations = generateXAxisAnnotations(
    projections,
    valuation,
    localCurrency,
    offerConfig,
    compStructure
  );

  const breakdown = (
    <Frame key="breakdown" offer={offer} offerConfig={offerConfig}>
      <Breakdown
        data={compData}
        offer={offer}
        projections={projections}
        xAxisAnnotations={xAxisAnnotations}
        valuation={valuation}
        compStructure={compStructure}
        offerConfig={offerConfig}
        localCurrency={localCurrency}
        equityCurrency={equityCurrency}
      />
    </Frame>
  );

  const cumulative = (
    <Frame key="cumulative" offer={offer} offerConfig={offerConfig}>
      <Cumulative
        data={compData}
        offer={offer}
        projections={projections}
        xAxisAnnotations={xAxisAnnotations}
        valuation={valuation}
        compStructure={compStructure}
        offerConfig={offerConfig}
      />
    </Frame>
  );

  return offerConfig.showAnnualizedEquity
    ? [breakdown, cumulative]
    : [cumulative];
}

export function getBenefitsFrames({
  benefitsPackage,
  hasAnyValue,
  offer,
  offerConfig,
}: {
  benefitsPackage: BenefitsPackage | null;
  hasAnyValue: boolean;
  offer: { offeredAt: Date | string };
  offerConfig: OfferConfig;
}): ReactElement<FrameProps>[] {
  if (benefitsPackage === null) {
    return [];
  }
  let benefitsFrames: ReactElement<FrameProps>[] = [];
  const benefits = benefitsPackage.benefits;

  for (let i = 0; i < benefits.length; i += 6) {
    benefitsFrames = benefitsFrames.concat(
      <Frame key={`benefits-${i}`} date offer={offer} offerConfig={offerConfig}>
        <Benefits
          benefits={benefits.slice(i, i + 6)}
          hasAnyValue={hasAnyValue}
          offerConfig={offerConfig}
        />
      </Frame>
    );
  }

  return benefitsFrames;
}

export const generateXAxisAnnotations = (
  projections: Money[],
  valuation: Valuation,
  cashCurrency: Currency,
  offerConfig: OfferConfig,
  compStructure: CompStructure
): string[][] => {
  return projections.map((scenarioValuation, idx) => {
    const unitPrice = [
      `\n${formatCurrency(
        exchangeCurrency(
          estimatedPrice(valuation, scenarioValuation),
          cashCurrency
        ),
        {
          notation: "compact",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}/unit`,
    ];
    const annotation = splitSentenceIntoLines(
      offerConfig.xAxisAnnotations[idx] ?? ""
    );
    if (compStructure.equityGrantMethod === EquityGrantMethod.UNITS) {
      return [...unitPrice, ...annotation];
    } else {
      return annotation;
    }
  });
};

export type CompBreakdown = {
  maxProjected: Money;
  totalCash: Money;
  totalComp: Money;
  equity?: {
    projectedAnnualValues: Money[];
    units: number;
    type: string;
    money: { annual: Money; total: Money };
  };
  cash: {
    [type in CashBandType]: {
      name: CashBandName;
      money: Money;
      bandType: CashBandType;
      schedule: ScheduleType;
    }[];
  };
};

export const compBreakdown = (
  valuationCurrency: Currency,
  localCurrency: Currency,
  equityCurrency: Currency,
  offer: Offer,
  valuation: Valuation,
  compStructure: CompStructure,
  projections: Money[]
): CompBreakdown => {
  const cashBandComponents = offer.cash.data
    .map(({ type, money }) => ({
      name: type as CashBandName,
      money,
      bandType: CASH_COMP_COMPONENTS[type as CashBandName].bandType,
      schedule: CASH_COMP_COMPONENTS[type as CashBandName].scheduleType,
    }))
    .sort(bandComparator);

  const equityBandComponents = offer.equity.data
    .filter((data) => data.type !== EquityBandName.EQUITY_REFRESH_GRANT)
    .map(({ type, units, money }) => ({
      type: type as EquityBandName,
      units,
      money,
    }));

  const cashBreakdown: CompBreakdown["cash"] = {
    [CashBandType.FIXED_CASH]: [],
    [CashBandType.VARIABLE_CASH]: [],
  };

  const isHourly = isHourlyType(offer.position?.type);
  const workHoursPerYear =
    compStructure.employmentHoursPerWeek * compStructure.employmentWeeksPerYear;
  let maxProjected = zero(localCurrency.code);
  let totalCash = zero(localCurrency.code);
  let totalEquity = zero(equityCurrency.code);

  cashBandComponents.forEach((comp) => {
    if (comp.money.value === 0) return;

    maxProjected = add(maxProjected, comp.money);
    totalCash = isHourly
      ? add(totalCash, multiply(comp.money, workHoursPerYear))
      : add(totalCash, comp.money);
    cashBreakdown[comp.bandType].push(comp);
  });

  let equityBreakdown: CompBreakdown["equity"];

  equityBandComponents.forEach((comp) => {
    const equivalentUnits =
      calculateEquivalentUnits(
        equityCurrency,
        valuationCurrency,
        comp,
        valuation
      ) ?? 0;

    const projectedAnnualValues = [];

    for (let i = 0; i < projections.length; i++) {
      projectedAnnualValues[i] = estimatedAnnualGrossEquityValue(
        valuation,
        exchangeFromTo(
          projections[i],
          valuationCurrency,
          equityCurrency.code === localCurrency.code
            ? localCurrency
            : valuationCurrency
        ),
        equivalentUnits,
        compStructure.vestingMonths
      );
    }

    const currentAnnualValue =
      comp.money !== null
        ? divide(comp.money, compStructure.vestingMonths / MONTHS_IN_A_YEAR)
        : exchangeFromTo(
            annualGrossEquityValue(
              valuation.fdso,
              valuation.valuation,
              equivalentUnits,
              compStructure.vestingMonths
            ),
            valuationCurrency,
            equityCurrency.code === localCurrency.code
              ? localCurrency
              : valuationCurrency
          );

    equityBreakdown = {
      type: "Equity",
      units: equivalentUnits + (equityBreakdown?.units ?? 0),
      projectedAnnualValues,
      money: {
        annual: currentAnnualValue,
        total:
          comp.money ??
          totalGrossEquityValue(
            valuation.fdso,
            valuation.valuation,
            equivalentUnits
          ),
      },
    };

    maxProjected =
      projectedAnnualValues.length > 0
        ? add(
            maxProjected,
            exchangeFromTo(
              projectedAnnualValues[projectedAnnualValues.length - 1],
              equityCurrency,
              localCurrency
            )
          )
        : maxProjected;

    totalEquity = add(totalEquity, currentAnnualValue);
  });

  return {
    maxProjected,
    totalCash,
    // total comp is always going to be in `localCurrency`, so we need to
    // convert from `equityCurrency` (whatever it might be) to `localCurrency`
    totalComp: add(
      totalCash,
      exchangeFromTo(totalEquity, equityCurrency, localCurrency)
    ),
    cash: cashBreakdown,
    equity: equityBreakdown,
  };
};

const calculateEquivalentUnits = (
  equityCurrency: Currency,
  valuationCurrency: Currency,
  equity: { units: number | null; money: Money | null },
  valuation: Valuation
): number | null => {
  if (equity.units !== null) return equity.units;
  if (equity.money === null) return null;

  const { valuation: valuationMoney } = valuation;

  // we need make sure that the equity value and the valuation value are in the
  // same currency before we can calculate the equivalent units
  const equityCashInValuationCurrency = exchangeFromTo(
    equity.money,
    equityCurrency,
    valuationCurrency
  );

  return (
    equityCashInValuationCurrency.value /
    (valuationMoney.value / valuation.fdso)
  );
};
