import { round } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import { GuidanceMatrixGuide_matrixGuide as MatrixGuide } from "src/__generated__/graphql";

export const getOrderedRanges = (
  guides?: MatrixGuide[]
): { id: number; rangeStart: number }[] => {
  if (guides == null) {
    return [];
  }

  return uniqBy(
    guides.map((guide) => guide.matrixRange),
    "id"
  )
    .sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1))
    .map((range) => ({ id: range.id, rangeStart: range.rangeStart }));
};

export const buildMatrix = (
  guides?: MatrixGuide[]
): { label: string | number; id?: number }[][] => {
  if (guides == null) {
    return [[]];
  }

  const orderedPerfRatings = uniqBy(
    guides.map((guide) => guide.matrixPerfRatingOption),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  const orderedRanges = uniqBy(
    guides.map((guide) => guide.matrixRange),
    "id"
  ).sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1));

  const matrix: { label: string; id?: number }[][] = [];

  orderedPerfRatings.forEach((rating, ratingIdx) => {
    matrix[ratingIdx] = [{ label: rating.name, id: rating.id }];
    orderedRanges.forEach((range) => {
      const guide = guides.find(
        (guide) =>
          guide.matrixPerfRatingOption.id === rating.id &&
          guide.matrixRange.id === range.id
      );
      if (guide != null) {
        matrix[ratingIdx].push({
          label: round(guide.percent * 100, 3),
          id: guide.id,
        });
      } else {
        matrix[ratingIdx].push({ label: "-" });
      }
    });
  });

  return matrix;
};
