import { gql } from "@apollo/client";
import { isHourlyType } from "@asmbl/shared/compensation";
import { CurrencyCode, EquityBandName } from "@asmbl/shared/constants";
import { Currency, hourlyToAnnual } from "@asmbl/shared/currency";
import { Money, isZero } from "@asmbl/shared/money";
import { formatNumeral, isEmptyString } from "@asmbl/shared/utils";
import { Box, Typography } from "@material-ui/core";
import { formatNumber } from "accounting";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import {
  BandUnit,
  BenefitsPackageFields as BenefitsPackage,
  GetAllOfferData,
  GetOrganization,
  Confirmation_position as Position,
} from "../../../__generated__/graphql";
import {
  COMP_TYPES_EXCLUDED_FROM_OFFERS,
  CashBandName,
} from "../../../constants";
import { getSimpleCashLabel } from "../../../models/Currency";
import { exchangeRate } from "../../../models/Money";
import { ComputedOfferedComp, isCashCompensation } from "../../../models/Offer";
import { NonNull, bandNameComparator } from "../../../utils";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { ApprovalSheet } from "../../OfferApproval/ApprovalSheet";
import Section from "../../Workflow/Section";
import { useStyles } from "../style";
import { OfferData } from "../types";

type CompStructure = NonNull<GetAllOfferData["compStructure"]>;
type Organization = GetOrganization["organization"];

export type Props = {
  currentStep: number;
  data: OfferData;
  position: Position | undefined;
  compStructure: CompStructure;
  benefitsPackages: BenefitsPackage[];
  organization: Organization;
  requireApproval: boolean;
  onSubmit: () => Promise<number | undefined>;
  onBack: () => unknown;
  showEquityInValuationCurrency: boolean;
  localCurrency: Currency<CurrencyCode>;
  valuationCurrency: Currency<CurrencyCode>;
  showCurrentEquityValue: boolean;
  computedOfferedComp: ComputedOfferedComp;
  pricePerUnit: Money;
};

const MemoizedConfirmation = memo(function Confirmation({
  currentStep,
  data,
  position,
  compStructure,
  benefitsPackages,
  organization,
  requireApproval,
  onSubmit,
  onBack,
  showEquityInValuationCurrency,
  showCurrentEquityValue,
  computedOfferedComp,
  localCurrency,
  valuationCurrency,
  pricePerUnit,
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const navigate = useNavigate();
  const intercom = useIntercom();
  const { currencies } = useCurrencies();

  const confirmationRef = useRef<HTMLElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleSubmit = async () => {
    if (requireApproval) {
      setIsDownloading(true);
      await downloadPdf();
      setIsDownloading(false);
    }
    // Write the offer contents to the DB after downloading the approval PDF
    const offerId = await onSubmit();
    if (offerId !== undefined) {
      intercom.trackEvent("Offer Created", { offerId, requireApproval });
      return requireApproval
        ? navigate("/offers")
        : navigate(`/offers/${offerId}`);
    } else {
      enqueueSnackbar("An error occurred and the offer could not be created.", {
        variant: "error",
      });
    }
  };

  const downloadPdf = async () => {
    const element = confirmationRef.current;
    if (element === null) return;

    const w = element.offsetWidth;
    const h = element.offsetHeight;
    const elementPng = await toPng(element);

    const pdf = new jsPDF("p", "pt", [w, h], true);
    pdf.addImage(elementPng, "PNG", 0, 0, w, h, "");

    const { firstName = "", lastName = "" } = data;
    pdf.save(`${firstName}${lastName}-OfferApproval.pdf`);
  };

  const equityCurrency = showEquityInValuationCurrency
    ? valuationCurrency
    : localCurrency;
  const benefitsCurrency =
    currencies.get(
      computedOfferedComp.annual.benefits.inBenefitsCurrency.currency
    ) ?? localCurrency;
  const foreignCurrencies = [equityCurrency, benefitsCurrency].filter(
    (c) => c.code !== localCurrency.code
  );

  return (
    <Box
      id="confirmation"
      className={requireApproval ? classes.wideContent : classes.content}
    >
      <Section
        id="Section-Confirmation"
        active={currentStep === 5}
        description="You can use the navigation on the left to go back and correct things."
        header="Double-check everything is correct."
      >
        {requireApproval ? (
          <ApprovalSheet
            ref={confirmationRef}
            data={data}
            position={position}
            compStructure={compStructure}
            benefitsPackages={benefitsPackages}
            organization={organization}
            showCurrentEquityValue={showCurrentEquityValue}
            computedOfferedComp={computedOfferedComp}
            localCurrency={localCurrency}
            valuationCurrency={valuationCurrency}
            showEquityInValuationCurrency={showEquityInValuationCurrency}
            pricePerUnit={pricePerUnit}
          />
        ) : (
          <>
            <Box className={classes.confirmationContainer}>
              <LabelValue
                label="Candidate Name"
                value={`${data.firstName ?? ""} ${data.lastName ?? ""}`}
              />
              <LabelValue
                label="Offer Date"
                value={data.offeredAt?.toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              />
              <LabelValue
                label="Job Title"
                value={data.title ?? data.position?.name}
              />
              {!isEmptyString(data.locationGroup?.name) && (
                <LabelValue label="Location" value={data.locationGroup?.name} />
              )}
              {benefitsPackages.length > 0 && (
                <LabelValue
                  label="Benefits Package"
                  value={
                    data.benefitsPackage === null
                      ? "Don't show benefits"
                      : data.benefitsPackage?.name ?? "None selected"
                  }
                />
              )}

              <Box m={3} />

              {compStructure.allBandTypes
                .slice()
                .filter(
                  (bandType) =>
                    !COMP_TYPES_EXCLUDED_FROM_OFFERS.includes(
                      bandType as EquityBandName
                    )
                )
                .sort(bandNameComparator)
                .map((bandName: string) => {
                  const bandData = isCashCompensation(bandName)
                    ? data.cash[bandName as CashBandName]
                    : data.equity[bandName as EquityBandName];

                  let value = "";

                  if (
                    bandData !== undefined &&
                    bandData.mode === BandUnit.CASH &&
                    bandData.value !== undefined
                  ) {
                    value = getSimpleCashLabel(bandData.value);
                    if (
                      isHourlyType(position?.type) &&
                      bandName === CashBandName.SALARY
                    ) {
                      value = `${value}/hr (${getSimpleCashLabel(
                        hourlyToAnnual(
                          compStructure.employmentHoursPerWeek *
                            compStructure.employmentWeeksPerYear,
                          bandData.value
                        )
                      )}/yr*)`;
                    }
                  } else if (
                    bandData !== undefined &&
                    bandData.mode === BandUnit.PERCENTAGE &&
                    bandData.value !== undefined
                  ) {
                    value = formatNumeral(bandData.value / 100, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    });
                  } else if (
                    bandData !== undefined &&
                    bandData.mode === BandUnit.UNITS &&
                    bandData.value !== undefined
                  ) {
                    value = `${formatNumeral(bandData.value)} units`;
                  }
                  return (
                    <LabelValue key={bandName} label={bandName} value={value} />
                  );
                })}

              {data.benefitsPackage &&
                !isZero(
                  computedOfferedComp.annual.benefits.inBenefitsCurrency
                ) && (
                  <LabelValue
                    label="Estimated Benefits Value"
                    value={getSimpleCashLabel(
                      computedOfferedComp.annual.benefits.inBenefitsCurrency
                    )}
                  />
                )}

              <LabelValue
                mt={3}
                label="Annual Cash OTE (On-Target Earnings)"
                value={getSimpleCashLabel(computedOfferedComp.annual.cash)}
              />

              <LabelValue
                label="Annual Total Compensation"
                value={getSimpleCashLabel(computedOfferedComp.annual.total)}
              />

              <LabelValue
                label="Additional One-Time Compensation"
                value={getSimpleCashLabel(computedOfferedComp.oneTimeComp)}
              />

              {isHourlyType(position?.type) && (
                <>
                  <Box mt={3} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <AssembleTypography
                      variant="productMicrocopy"
                      color="textSecondary"
                    >
                      *Annual is based on a{" "}
                      {formatNumber(
                        compStructure.employmentHoursPerWeek *
                          compStructure.employmentWeeksPerYear
                      )}{" "}
                      hour work-week.
                    </AssembleTypography>
                  </Box>
                </>
              )}
            </Box>

            {foreignCurrencies.length > 0 &&
              foreignCurrencies.map((foreignCurrency) => (
                <Box mt={1} key={foreignCurrency.code}>
                  <AssembleTypography variant="productMicrocopy" align="right">
                    {exchangeRate(foreignCurrency, localCurrency)}
                  </AssembleTypography>
                </Box>
              ))}
          </>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={4}
        >
          <AssembleButton
            onClick={onBack}
            variant="outlined"
            size="medium"
            label="Back"
          />
          <AssembleButton
            onClick={handleSubmit}
            disabled={isDownloading}
            variant="contained"
            size="medium"
            label={
              requireApproval
                ? isDownloading
                  ? "Downloading PDF..."
                  : "Download Approval PDF"
                : "Generate Offer"
            }
          />
        </Box>
      </Section>
    </Box>
  );
});

export const Confirmation = Object.assign(MemoizedConfirmation, {
  fragments: {
    position: gql`
      ${ApprovalSheet.fragments.position}
      fragment Confirmation_position on Position {
        ...ApprovalSheet_position
      }
    `,
  },
});

function LabelValue({
  label,
  value,
  mt,
  mb = 1,
}: {
  label: string | number | undefined;
  value: string | number | undefined;
  mt?: number;
  mb?: number;
}) {
  const classes = useStyles();

  return (
    <Box
      className={classes.fullWidth}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mt={mt}
      mb={mb}
    >
      <Typography variant="body1" className={classes.labelText}>
        {label}
      </Typography>
      <Typography variant="body1" className={classes.valueText}>
        {value}
      </Typography>
    </Box>
  );
}
