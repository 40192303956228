import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyComp } from "@asmbl/shared/compensation";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { isZero, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CurrencyCode,
  CondensedTableSalaryIncreaseCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { DefaultCell } from "./DefaultCell";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTablePayIncreaseCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  if (
    !employee.compRecommendation ||
    isZero(
      employee.compRecommendation.latestSubmittedPayIncrease
        .annualCashEquivalent
    )
  ) {
    return <DefaultCell />;
  }

  const showHourly = Boolean(
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees
  );
  const { annualCashEquivalent, hourlyCashEquivalent, unitType } =
    employee.compRecommendation.latestSubmittedPayIncrease;

  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const promoItem = employee.compRecommendation.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  );
  // show increase in promotion unit type if it exists
  const positionType = isHourlyComp(promoItem ? unitType : currentPay?.unit);
  const isHourly = showHourly && positionType;

  const increase = isHourly ? hourlyCashEquivalent : annualCashEquivalent;
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(increase, false, isHourly)}
    </AssembleTruncatedTypography>
  );
}

CondensedTablePayIncreaseCell.fragments = {
  participant: gql`
    fragment CondensedTableSalaryIncreaseCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          unit
          annualCashEquivalent
          employeeId
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
        }
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
      }
    }
  `,
};

CondensedTablePayIncreaseCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_DOLLAR_CHANGE
);
CondensedTablePayIncreaseCell.id = ColumnIds.SALARY_DOLLAR_CHANGE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTablePayIncreaseCell,
  Header: CondensedTablePayIncreaseCell.Header,
  id: CondensedTablePayIncreaseCell.id,
  width: LARGE_COL_WIDTH,
};
CondensedTablePayIncreaseCell.column = column;
CondensedTablePayIncreaseCell.ordering = ({
  defaultCurrency,
  currencies,
}: {
  defaultCurrency: Currency;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const latestPayIncrease =
      e.compRecommendation?.latestSubmittedPayIncrease.annualCashEquivalent ??
      zero(defaultCurrency.code);

    const currentCurrency = currencies.get(latestPayIncrease.currency);

    if (currentCurrency == null) {
      return 0;
    }

    return exchangeFromTo(latestPayIncrease, currentCurrency, defaultCurrency)
      .value;
  });
