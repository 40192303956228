import { gql } from "@apollo/client";
import { isHourlyComp } from "@asmbl/shared/compensation";
import { add, Money, ratio } from "@asmbl/shared/money";
import { formatNumeral, mapMaybe } from "@asmbl/shared/utils";
import {
  ActualVariablePayChange_cashCompensation,
  ActualVariablePayChange_recItem,
  CashCompType,
  RecItemType,
} from "../../../__generated__/graphql";
import { getTotalCash } from "../../../models/CashCompensation";
import {
  CompItemRecommendationTypeTitle,
  whereType,
} from "../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../models/Currency";
import { AssembleTypography } from "../../AssembleTypography";
import { CompComponentContainer } from "./CompComponentContainer";
import { BadgedMoneyValue, CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

type RecItemWithValue = ActualVariablePayChange_recItem & {
  recommendedCashValue: Money;
};

export function ActualVariablePayChange({
  cashCompensations,
  recItems,
}: {
  cashCompensations: ActualVariablePayChange_cashCompensation[];
  recItems: ActualVariablePayChange_recItem[];
}): JSX.Element | null {
  const actualRecurringBonusRecItem = recItems
    .filter(
      (item): item is RecItemWithValue => item.recommendedCashValue !== null
    )
    .find(whereType(RecItemType.ACTUAL_RECURRING_BONUS));
  const meritBonusRecItem = recItems
    .filter(
      (item): item is RecItemWithValue => item.recommendedCashValue !== null
    )
    .find(whereType(RecItemType.MERIT_BONUS));

  if (actualRecurringBonusRecItem == null && meritBonusRecItem == null) {
    return null;
  }

  const actualRecItems = mapMaybe(
    [actualRecurringBonusRecItem, meritBonusRecItem],
    (i) => i
  );

  const currentTarget = getTotalCash(cashCompensations)?.subcomponents.find(
    (item) => item.type === CashCompType.RECURRING_BONUS
  )?.annualCashEquivalent;
  const targetRatio =
    actualRecurringBonusRecItem && currentTarget
      ? ratio(actualRecurringBonusRecItem.recommendedCashValue, currentTarget)
      : null;

  return (
    <CompComponentContainer>
      <LabelValue
        label={
          <AssembleTypography variant="productEyebrowSmall" gutterBottom>
            Actual Variable Pay
          </AssembleTypography>
        }
        compValue={
          <BadgedMoneyValue
            value={actualRecItems
              .map((item) => item.recommendedCashValue)
              .reduce(add)}
          />
        }
      />
      {actualRecurringBonusRecItem && (
        <LabelValue
          label={
            CompItemRecommendationTypeTitle[
              actualRecurringBonusRecItem.recommendationType
            ]
          }
          compValue={
            <CompValue
              value={`${getSimpleCashLabel(
                actualRecurringBonusRecItem.recommendedCashValue,
                false,
                isHourlyComp(actualRecurringBonusRecItem.unitType)
              )}${
                targetRatio != null
                  ? ` | ${formatNumeral(targetRatio, {
                      style: "percent",
                    })} current target bonus`
                  : ""
              }`}
            />
          }
        />
      )}
      {meritBonusRecItem && (
        <LabelValue
          label={
            CompItemRecommendationTypeTitle[
              meritBonusRecItem.recommendationType
            ]
          }
          compValue={
            <CompValue
              value={getSimpleCashLabel(meritBonusRecItem.recommendedCashValue)}
            />
          }
        />
      )}
    </CompComponentContainer>
  );
}

ActualVariablePayChange.fragments = {
  recItem: gql`
    fragment ActualVariablePayChange_recItem on RecItem {
      recommendationType
      recommendedCashValue
      recommendedPercentValue
      unitType
    }
  `,
  cashCompensation: gql`
    fragment ActualVariablePayChange_cashCompensation on CashCompensation {
      type
      annualCashEquivalent
      hourlyCashEquivalent
      unit
      percentOfSalary
    }
  `,
};
