import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyComp } from "@asmbl/shared/compensation";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { calculatePercent } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CurrencyCode,
  CondensedTableSalaryMeritPercentCell2_participant as Participant,
  RecItemType,
} from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const meritItem = recItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );
  if (!currentPay || !meritItem)
    return (
      <AssembleTruncatedTypography align="right">
        0%
      </AssembleTruncatedTypography>
    );

  const isHourly = showHourly && isHourlyComp(meritItem.unitType);

  const percent = calculatePercent(
    isHourly
      ? currentPay.hourlyCashEquivalent
      : currentPay.annualCashEquivalent,
    getMeritSalaryIncrease(recItems, currentPay.annualCashEquivalent.currency)
      .value
  );

  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(percent / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryMeritPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritPercentCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          employeeId
          unit
          annualCashEquivalent
          hourlyCashEquivalent
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }
    }
  `,
};

CondensedTableSalaryMeritPercentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_PERCENT
);
CondensedTableSalaryMeritPercentCell2.id = ColumnIds.SALARY_MERIT_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritPercentCell2,
  Header: CondensedTableSalaryMeritPercentCell2.Header,
  id: CondensedTableSalaryMeritPercentCell2.id,
};

CondensedTableSalaryMeritPercentCell2.column = column;
CondensedTableSalaryMeritPercentCell2.ordering = ({
  defaultCurrencyCode,
}: {
  defaultCurrencyCode: CurrencyCode;
}) =>
  contramap((e: Participant) => {
    const currentPay = getSalaryCashComp(e.subject.activeCashCompensation);
    const meritItem = e.compRecommendation?.latestSubmittedItems.find(
      (item) => item.recommendationType === RecItemType.MERIT_INCREASE
    );
    const pay = isHourlyComp(meritItem?.unitType)
      ? currentPay?.hourlyCashEquivalent
      : currentPay?.annualCashEquivalent;

    return calculatePercent(
      pay,
      getMeritSalaryIncrease(
        e.compRecommendation?.latestSubmittedItems ?? [],
        pay?.currency ?? defaultCurrencyCode
      ).value
    );
  });
