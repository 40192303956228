import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import { useState } from "react";
import { useAuth } from "src/components/Auth/AuthContext";
import { getGuidance } from "src/models/MeritGuidance";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  CompCycleSingleRec_matrixPerfRatingOption,
  CompCycleSingleRecQuery,
  CompCycleSingleRecQueryVariables,
  CurrencyCode,
  Noun,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../LoadingSpinner";
import { CompCycleSingleRecModal } from "./CompCycleSingleRecModal";

type Props = {
  compCycleId: number;
  employeeId: number;
  isIndirectReport: boolean;
  isPhaseConfigurationPeriod: boolean;
  onClose: () => unknown;
  employeeCurrency: string | null;
};

export function CompCycleSingleRecLoadingBoundary({
  compCycleId,
  employeeId,
  isIndirectReport,
  isPhaseConfigurationPeriod,
  employeeCurrency,
  onClose,
}: Props): JSX.Element {
  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;
  const hasGlobalCycleAccess = permissions.canViewGlobal(Noun.CompCycle);
  const selectedCurrency = employeeCurrency as CurrencyCode;
  const { data, loading } = useQuery<
    CompCycleSingleRecQuery,
    CompCycleSingleRecQueryVariables
  >(CompCycleSingleRecLoadingBoundary.query, {
    variables: {
      compCycleId,
      employeeId,
      currencyCode: selectedCurrency,
      actingManagerEmployeeId,
      isAdmin: hasGlobalCycleAccess,
    },
    onCompleted: (data) => {
      setRevisedPerfRating(data.compCycle2?.participant?.perfRating ?? null);
    },
  });

  const [revisedPerfRating, setRevisedPerfRating] = useState<string | null>(
    null
  );

  if (!data || !data.valuation || !data.compCycle2?.participant) {
    if (loading) {
      return <LoadingDialogContent />;
    }
    return <>An error occurred.</>;
  }

  const meritMatrix = data.compCycle2.matrices.find(
    (matrix) => matrix.type === "MERIT"
  );

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions: CompCycleSingleRec_matrixPerfRatingOption[] = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidance = getGuidance(
    {
      ...data.compCycle2.participant.subject,
      perfRating: revisedPerfRating ?? data.compCycle2.participant.perfRating,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  return (
    <CompCycleSingleRecModal
      selectedCurrency={selectedCurrency}
      handleClose={onClose}
      compCycleId={compCycleId}
      employee={data.compCycle2.participant}
      isIndirectReport={isIndirectReport}
      isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
      positions={data.positions}
      currentValuation={data.valuation}
      compCycle={data.compCycle2}
      guidance={guidance ?? undefined}
      perfRatingOptions={perfRatingOptions}
      revisedPerfRating={revisedPerfRating}
      setRevisedPerfRating={setRevisedPerfRating}
      budget={data.compCycleBudget ?? null}
    />
  );
}

const MATRIX_RANGE = gql`
  fragment CompCycleSingleRec_matrixRange on MatrixRange {
    id
    rangeStart
  }
`;

const MATRIX_PERF_RATING_OPTION = gql`
  fragment CompCycleSingleRec_matrixPerfRatingOption on MatrixPerfRatingOption {
    id
    name
    rank
  }
`;

const MATRIX_GUIDE = gql`
  ${MATRIX_RANGE}
  ${MATRIX_PERF_RATING_OPTION}
  fragment CompCycleSingleRec_matrixGuide on MatrixGuide {
    id
    percent
    matrixRange {
      id
      ...CompCycleSingleRec_matrixRange
    }
    matrixPerfRatingOption {
      id
      ...CompCycleSingleRec_matrixPerfRatingOption
    }
    matrixId
  }
`;

const MATRIX = gql`
  ${MATRIX_RANGE}
  ${MATRIX_PERF_RATING_OPTION}
  ${MATRIX_GUIDE}
  fragment CompCycleSingleRec_matrix on Matrix {
    id
    type
    siblingMatrixId
    matrixGuides {
      id
      ...CompCycleSingleRec_matrixGuide
    }
  }
`;

CompCycleSingleRecLoadingBoundary.query = gql`
  ${CompCycleSingleRecModal.fragments.participant}
  ${CompCycleSingleRecModal.fragments.position}
  ${CompCycleSingleRecModal.fragments.compCycle}
  ${CompCycleSingleRecModal.fragments.compCycleBudget}
  ${CompCycleSingleRecModal.fragments.valuation}
  ${MATRIX}
  query CompCycleSingleRecQuery(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode
    $skipEligibility: Boolean = true
    $actingManagerEmployeeId: Int
    $isAdmin: Boolean!
  ) {
    compCycle2(id: $compCycleId) {
      id
      ...CompCycleSingleRecModal_compCycle
      participant(id: $employeeId) {
        subjectId
        compCycleId
        ...CompCycleSingleRecModal_participant
        subject {
          adjustedCashBands {
            id
            name
            bandPoints {
              id
              bandName
              name
              value {
                ... on CashValue {
                  annualRate
                  hourlyRate
                  currencyCode
                }
              }
            }
          }
          activeCashCompensation(currencyCode: $currencyCode) {
            type
            annualCashEquivalent
            hourlyCashEquivalent
            unit
            employeeId
          }
        }
      }
      recommendationsPreFill
      matrices {
        id
        ...CompCycleSingleRec_matrix
      }
    }
    compCycleBudget(compCycleId: $compCycleId) @include(if: $isAdmin) {
      ...CompCycleSingleRecModal_compCycleBudget
    }
    positions {
      id
      ...CompCycleSingleRecModal_position
    }
    valuation {
      id
      ...CompCycleSingleRecModal_valuation
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(5),
  },
}));

function LoadingDialogContent(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.dialogContent}>
      <LoadingSpinner />
    </div>
  );
}
