import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyComp } from "@asmbl/shared/compensation";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CurrencyCode,
  CondensedTableSalaryMarketCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMarketSalaryIncrease } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMarketCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const itemMap = new Map(
    recItems.map((item) => [item.recommendationType, item])
  );
  const marketItem = itemMap.get(RecItemType.MARKET);

  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const isHourly = showHourly && isHourlyComp(marketItem?.unitType);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        marketItem?.recommendedCashValue ??
          zero(
            currentPay?.annualCashEquivalent.currency ?? defaultCurrencyCode
          ),
        false,
        isHourly
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryMarketCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMarketCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          employeeId
          unit
          annualCashEquivalent
          hourlyCashEquivalent
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }
    }
  `,
};

CondensedTableSalaryMarketCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MARKET
);
CondensedTableSalaryMarketCell2.id = ColumnIds.SALARY_MARKET;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMarketCell2,
  Header: CondensedTableSalaryMarketCell2.Header,
  id: CondensedTableSalaryMarketCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryMarketCell2.column = column;
CondensedTableSalaryMarketCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const marketSalaryIncrease = getMarketSalaryIncrease(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(marketSalaryIncrease.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      marketSalaryIncrease,
      currentCurrency,
      defaultCurrency
    ).value;
  });
