import { getSalaryCashComp } from "@asmbl/shared/compensation";
import uniqBy from "lodash/uniqBy";
import {
  CashCompType,
  CompUnit,
  CurrencyCode,
  CondensedTable_matrix as Matrix,
  BulkActionsBar_participant as Participant,
  RecItemInput,
  RecItemType,
} from "src/__generated__/graphql";
import { getCompaRatioNew } from "./Employee";

// Determine which merit guidance option should be returned for a given employee
export const getGuidance = (
  employee: {
    adjustedCashBands:
      | {
          id: string;
          name: string;
          bandPoints: {
            __typename: string;
            id: string;
            bandName: string;
            name: string;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              hourlyRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            };
          }[];
        }[]
      | null;
    activeCashCompensation:
      | {
          __typename: "CashCompensation2";
          type: CashCompType;
          annualCashEquivalent: GraphQL_Money;
          unit: CompUnit;
          hourlyCashEquivalent: GraphQL_Money;
        }[]
      | null;
    perfRating: string | null;
  },
  matrixGuides: {
    perfRatingOptionId: number;
    matrixRangeId: number;
    percent: number;
  }[],
  matrixRanges: {
    rangeStart: number;
    id: number;
  }[],
  perfRatingOptions: {
    name: string;
    id: number;
  }[]
): number | null => {
  // If all merit guidance options are 0, then guidance hasn't been set
  if (matrixGuides.every((guide) => guide.percent === 0)) {
    return null;
  }

  // Find the employee's comparatio, since merit guidance is based on this
  const compaRatio = getCompaRatioNew(
    employee.activeCashCompensation,
    employee.adjustedCashBands
  );

  if (compaRatio == null) return null;

  const sorted = matrixRanges
    .slice(0)
    .sort((a, b) => a.rangeStart - b.rangeStart);

  // Find which range the employee's comparatio puts them in
  const matrixRange = sorted.find((range, i) => {
    if (i === 0 && range.rangeStart > compaRatio) {
      return true;
    }
    if (i === sorted.length - 1) {
      return true;
    }
    if (
      range.rangeStart <= compaRatio &&
      sorted[i + 1].rangeStart > compaRatio
    ) {
      return true;
    }
  });

  if (!matrixRange) return null;

  // Find which perf rating the employee has been given
  // If there is only one without a name, it applies to all employees
  const perfRating =
    perfRatingOptions.length === 1 && perfRatingOptions[0].name === ""
      ? perfRatingOptions[0]
      : perfRatingOptions.find((rating) => rating.name === employee.perfRating);

  if (!perfRating) return null;

  // Find the correct guidance based on a combination of their adjustment
  // range and perf rating
  const guide = matrixGuides.find(
    (guide) =>
      guide.perfRatingOptionId === perfRating.id &&
      guide.matrixRangeId === matrixRange.id
  );

  return guide?.percent ?? null;
};

export function generateGuidance(participant: Participant, matrices: Matrix[]) {
  const { compRecommendation } = participant;

  const basePay = getSalaryCashComp(participant.subject.activeCashCompensation);

  if (basePay == null) return;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const meritMatrix = matrices?.find((matrix) => matrix.type === "MERIT");

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidance = getGuidance(
    {
      ...participant,
      ...participant.subject,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  if (guidance == null) return;

  const guidanceItem = compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );

  // guidance already auto-applied, do not apply again
  if (guidanceItem && guidanceItem.recommendedPercentValue === guidance * 100)
    return;

  const latestItems = compRecommendation
    ? compRecommendation.latestSubmittedItems.filter(
        (item) => item.recommendationType !== RecItemType.MERIT_INCREASE
      )
    : [];

  const newItems: RecItemInput[] = [
    ...latestItems.map((item) => ({
      recommendationType: item.recommendationType,
      note: item.note,
      recommendedPercentValue: item.recommendedPercentValue,
      recommendedCashValue: item.recommendedCashValue,
      recommendedTitle: item.recommendedTitle,
      recommendedPositionId: item.recommendedPosition?.id,
      unitType: item.unitType,
    })),
    {
      recommendationType: RecItemType.MERIT_INCREASE,
      note: "Applied from guidance suggestion",
      recommendedPercentValue: guidance * 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    },
  ];

  return {
    items: newItems,
    subjectId: participant.subjectId,
  };
}
