import { gql } from "@apollo/client";
import { isHourlyComp } from "@asmbl/shared/compensation";
import { CashBandName } from "@asmbl/shared/constants";
import { formatNumeral, mapMaybe } from "@asmbl/shared/utils";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import {
  CashCompType,
  CompUnit,
  CurrentCompensation_employee as Employee,
} from "../../../../__generated__/graphql";
import NoBand from "../../../../assets/svgs/illustrations/empty-compensation.svg?react";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { NewCompSlider } from "../../../../components/CompSlider/NewCompSlider";
import { PaperHeader } from "../../../../components/PaperHeader";
import { ADJUSTED_CASH_BAND_POINT_FIELDS } from "../../../../fragments";
import { isBandPointDefined } from "../../../../models/BandPoint";
import {
  byCashCompType,
  isRecurringComp,
  zip,
} from "../../../../models/CashCompensation";
import {
  currencySymbol,
  getHourlyCashLabel,
} from "../../../../models/Currency";
import { GRAY_1, GRAY_4 } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "1rem",
  },
  compRow: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
  },
  compSlider: {
    margin: "2rem 2rem 2rem 0",
    flexGrow: 1,
    flexBasis: "25rem",
    position: "relative",
  },
  compRowValue: {},
  headerText: {
    color: GRAY_1,
  },
  currencySign: {
    fontSize: "1.25rem",
    letterSpacing: "normal",
    verticalAlign: "top",
  },
  totalValue: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "25%",
    marginTop: "1rem",
  },
  cash: { display: "flex", flexDirection: "row" },
  valueContainer: {
    display: "inline-block",
    verticalAlign: "top",
  },
  compValue: {
    fontSize: "2rem",
    letterSpacing: "-0.05em",
    lineHeight: 1,
  },
  compLabel: {
    color: GRAY_4,
  },
  noBandIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  noBand: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.375rem",
  },
}));

interface Props {
  employee: Employee;
}

export function CurrentCompensation({ employee }: Props): JSX.Element | null {
  const classes = useStyles();
  const { activeCashCompensation: cash, adjustedCashBands: bands } = employee;

  const salary = cash?.find(({ type }) => type === CashCompType.SALARY);

  if (salary === undefined) {
    return null;
  }

  const compAndBands = zip(cash ?? [], bands ?? []).sort(byCashCompType);
  const isHourly = isHourlyComp(salary.unit);
  const bandSliders = mapMaybe(compAndBands, ({ cash, band, type }, idx) => {
    if (band && !isRecurringComp(band)) return;

    const hasEmployeeData = cash !== undefined;

    // we consider having band data to be having a defined band with
    // at least one band point that has a defined cash value that is not 0
    const hasActualBandData =
      band?.bandPoints !== undefined &&
      band.bandPoints.length > 0 &&
      band.bandPoints.some(
        (bp) =>
          bp.value.annualRate?.value != null && bp.value.annualRate.value !== 0
      );

    // if the employee doesn't have any data for the given comp type and there
    // is no real band point data, then we'll just skip over this comp type
    if (!hasEmployeeData && !hasActualBandData) return;

    return (
      <div className={classes.compRow} key={`bandSlider-${idx}`}>
        <div className={classes.totalValue}>
          <div className={classes.cash}>
            {cash && cash.unit !== CompUnit.HOURLY_CASH && (
              <Typography component="span" className={classes.currencySign}>
                {currencySymbol(cash.annualCashEquivalent.currency)}
              </Typography>
            )}
            <Typography component="div" className={classes.compValue}>
              {cash
                ? `${
                    isHourlyComp(cash.unit)
                      ? getHourlyCashLabel(cash.hourlyCashEquivalent)
                      : formatNumeral(cash.annualCashEquivalent.value)
                  }`
                : "No Data"}
            </Typography>
          </div>
          <AssembleTypography
            variant="productEyebrow"
            className={classes.compLabel}
          >
            {CashBandName[type]}
          </AssembleTypography>
        </div>
        <div className={classes.compSlider}>
          {band?.bandPoints !== undefined &&
          band.bandPoints.some(isBandPointDefined) ? (
            <NewCompSlider
              band={band.bandPoints}
              values={cash ? [cash] : []}
              isHourly={isHourly}
            />
          ) : (
            <div className={classes.noBand}>
              <NoBand className={classes.noBandIcon} />
              <AssembleTypography
                style={{ color: GRAY_4 }}
                variant="productExtraSmall"
              >
                We don't have any band information
              </AssembleTypography>
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <Paper variant="outlined">
      <PaperHeader>Band Points</PaperHeader>
      <div className={classes.container}>{bandSliders}</div>
    </Paper>
  );
}

CurrentCompensation.fragments = {
  employee: gql`
    ${ADJUSTED_CASH_BAND_POINT_FIELDS}
    fragment CurrentCompensation_employee on Employee {
      id
      adjustedCashBands {
        id
        name
        bandPoints {
          id
          ...AdjustedCashBandPointFields
        }
      }
      activeCashCompensation {
        employeeId
        activeAt
        type
        annualCashEquivalent
        hourlyCashEquivalent
        percentOfSalary
        unit
      }
    }
  `,
};
