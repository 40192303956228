import { gql } from "@apollo/client";
import {
  getPayCashEquivalent,
  getSalaryCashComp,
  isHourlyComp,
} from "@asmbl/shared/compensation";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CashCompType,
  CurrencyCode,
  CondensedTablePrevSalaryCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { getExchangedSalary } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTablePrevPayCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const { defaultCurrencyCode } = useCurrencies();
  const pay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const isHourly = showHourly && isHourlyComp(pay?.unit);
  const displayedPay = pay
    ? getPayCashEquivalent(pay)
    : zero(defaultCurrencyCode);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(displayedPay, false, isHourly)}
    </AssembleTruncatedTypography>
  );
}

CondensedTablePrevPayCell.fragments = {
  participant: gql`
    fragment CondensedTablePrevSalaryCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
      }
    }
  `,
};

CondensedTablePrevPayCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURR_SALARY
);
CondensedTablePrevPayCell.id = ColumnIds.CURR_SALARY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTablePrevPayCell,
  Header: CondensedTablePrevPayCell.Header,
  id: CondensedTablePrevPayCell.id,
  width: LARGE_COL_WIDTH,
};
CondensedTablePrevPayCell.column = column;

CondensedTablePrevPayCell.ordering = ({
  defaultCurrency,
  currencies,
}: {
  defaultCurrency: Currency;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const salary =
      e.subject.activeCashCompensation?.find(
        (acc) => acc.type === CashCompType.SALARY
      )?.annualCashEquivalent ?? zero(defaultCurrency.code);

    const exchangedSalary = getExchangedSalary(
      defaultCurrency,
      { salary },
      currencies
    );

    return exchangedSalary ? exchangedSalary.value : 0;
  });
