import { gql } from "@apollo/client";
import { isHourlyType } from "@asmbl/shared/compensation";
import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import {
  Currency,
  exchangeFromTo,
  hourlyToAnnual,
} from "@asmbl/shared/currency";
import {
  Money,
  divide,
  max,
  min,
  percentageOfSalaryToCash,
  subtract,
  zero,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { ReactNode, useMemo } from "react";
import {
  BandUnit,
  GetAllOfferData,
  BandLabelValue_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import AlertingError from "../../assets/svgs/alerting/error.svg";
import AlertingSuccess from "../../assets/svgs/alerting/success.svg";
import { EquityBandName } from "../../constants";
import { getSimpleCashLabel } from "../../models/Currency";
import {
  ComputedOfferedComp,
  OfferDataValue,
  OfferDataValueCash,
} from "../../models/Offer";
import { GRAY_2, GRAY_4, GRAY_6, WHITE } from "../../theme";
import { NonNull } from "../../utils";

type CompStructure = NonNull<GetAllOfferData["compStructure"]>;

const useStyles = makeStyles((theme) => ({
  labelText: {
    color: GRAY_4,
    lineHeight: "1.8em",
  },
  messageIcon: {
    marginRight: theme.spacing(1),
  },
  messageBackground: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),

    "$outOfBand&": {
      background: `linear-gradient(145.58deg, #D91D4A 28.94%, #FF385C 87.8%)`,
      borderRadius: "3px",
    },
  },
  messageDescriptionText: {
    fontSize: "0.75em",
    color: GRAY_2,
    whiteSpace: "pre-wrap",

    "$outOfBand&": {
      color: WHITE,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  outOfBand: {},
  equityDivider: {
    flex: 1,
    height: "16px",
    borderBottom: `1px solid ${GRAY_6}`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  localCurrency: Currency<CurrencyCode>;
  valuationCurrency: Currency<CurrencyCode>;
  bandName: string;
  salary: OfferDataValueCash | undefined;
  compData: OfferDataValue;
  position: Position | undefined;
  compStructure: CompStructure;

  showCurrentEquityValue: boolean;
  showEquityInValuationCurrency: boolean;
  computedOfferedComp: ComputedOfferedComp;
  pricePerUnit: Money;
};

export function BandLabelValue({
  localCurrency,
  valuationCurrency,
  bandName,
  salary,
  compData,
  position,
  showCurrentEquityValue,
  showEquityInValuationCurrency,
  computedOfferedComp,
  pricePerUnit,
  compStructure,
}: Props): JSX.Element {
  const classes = useStyles();

  const bandValue = useMemo(() => {
    if (compData?.value === undefined) return "";

    switch (compData.mode) {
      case BandUnit.CASH: {
        if (bandName === EquityBandName.INITIAL_EQUITY_GRANT) {
          return showEquityInValuationCurrency
            ? getSimpleCashLabel(
                computedOfferedComp.fullyVested.inValuationCurrency
              )
            : getSimpleCashLabel(
                computedOfferedComp.fullyVested.inLocalCurrency
              );
        }

        return getSimpleCashLabel(compData.value);
      }
      case BandUnit.PERCENTAGE:
        return formatNumeral(compData.value / 100, {
          style: "percent",
          maximumFractionDigits: 2,
        });
      case BandUnit.UNITS:
        return formatNumeral(compData.value);
    }
  }, [
    bandName,
    compData?.mode,
    compData?.value,
    computedOfferedComp.fullyVested.inLocalCurrency,
    computedOfferedComp.fullyVested.inValuationCurrency,
    showEquityInValuationCurrency,
  ]);

  const isEquityBand = Object.values(EquityBandName).includes(
    bandName as EquityBandName
  );

  const message = useMemo(() => {
    if (position === undefined || compData?.value === undefined) {
      return undefined;
    }

    const allBandsInPosition = [
      ...(position.adjustedCashBands ?? []),
      ...(position.adjustedEquityBands ?? []),
    ];

    const selectedBand = allBandsInPosition.find((b) => b.name === bandName);
    if (selectedBand === undefined) return undefined;

    if (compData.mode === BandUnit.UNITS) {
      if (selectedBand.__typename !== "AdjustedEquityBand") return undefined;

      const bandPoints = selectedBand.bandPoints.map((p) => p.totalUnits ?? 0);
      return getBandMessageForUnits(
        bandName,
        bandPoints,
        compData.value,
        pricePerUnit,
        showCurrentEquityValue
      );
    }

    if (salary?.value === undefined) return undefined;
    const bandPoints =
      selectedBand.__typename === "AdjustedCashBand"
        ? selectedBand.bandPoints.map((p) => p.annualCashEquivalent)
        : selectedBand.bandPoints.map((p) => p.totalGrossValue);

    if (compData.mode === BandUnit.CASH) {
      const compDataValue =
        isHourlyType(position.type) && bandName === CashBandName.SALARY
          ? hourlyToAnnual(
              compStructure.employmentHoursPerWeek *
                compStructure.employmentWeeksPerYear,
              compData.value
            )
          : compData.value;

      return getBandMessage(
        bandName,
        bandPoints,
        compDataValue,
        pricePerUnit,
        showCurrentEquityValue,
        position.type
      );
    }

    // compData.mode === BandUnit.PERCENTAGE
    const cashFromPercentage = percentageOfSalaryToCash(
      salary.value,
      compData.value
    );

    // if we are using percent, we have to convert the percent of the
    // salary to the valuation currency if we are using equity
    const cashValue =
      isEquityBand && showEquityInValuationCurrency
        ? exchangeFromTo(cashFromPercentage, localCurrency, valuationCurrency)
        : cashFromPercentage;

    return getBandMessage(
      bandName,
      bandPoints,
      cashValue,
      pricePerUnit,
      showCurrentEquityValue,
      position.type
    );
  }, [
    position,
    compData?.value,
    compData?.mode,
    salary?.value,
    isEquityBand,
    showEquityInValuationCurrency,
    localCurrency,
    valuationCurrency,
    bandName,
    pricePerUnit,
    showCurrentEquityValue,
    compStructure.employmentHoursPerWeek,
    compStructure.employmentWeeksPerYear,
  ]);

  const error = message && message.outOfBand ? classes.outOfBand : "";

  const showCashValueOfEquity =
    isEquityBand && compData?.mode !== BandUnit.CASH && showCurrentEquityValue;

  const showHourlyLabel =
    bandName === CashBandName.SALARY &&
    isHourlyType(position?.type) &&
    compData?.mode === BandUnit.CASH;

  return (
    <>
      <LabelValue
        label={
          bandName === CashBandName.SALARY
            ? "Salary / Hourly Rate" // HACK: Verkada wants this name for the salary band
            : bandName
        }
        value={
          <Box component="span" display="flex" alignItems="center" flex={1}>
            {message && (
              <img src={message.icon} className={classes.messageIcon} />
            )}
            {bandValue}
            {compData?.value !== undefined &&
              showHourlyLabel &&
              `/hr (${getSimpleCashLabel(
                hourlyToAnnual(
                  compStructure.employmentHoursPerWeek *
                    compStructure.employmentWeeksPerYear,
                  compData.value
                )
              )}/yr*)`}
            {showCashValueOfEquity && (
              <>
                <span className={classes.equityDivider} />
                {getSimpleCashLabel(
                  showEquityInValuationCurrency
                    ? computedOfferedComp.fullyVested.inValuationCurrency
                    : computedOfferedComp.fullyVested.inLocalCurrency
                )}
              </>
            )}
          </Box>
        }
      />
      {message && (
        <Box className={`${classes.messageBackground} ${error}`}>
          <Typography className={`${classes.messageDescriptionText} ${error}`}>
            {message.description}
          </Typography>
        </Box>
      )}
    </>
  );
}

function getBandMessage(
  bandName: string,
  bandPoints: Money[],
  compData: Money,
  equityUnitPrice: Money,
  showCurrentEquityValue: boolean,
  positionType: PositionType
): { icon: string; description: string; outOfBand: boolean } {
  const currencyCode = compData.currency;
  const maxMoney = max(...bandPoints) ?? {
    value: Infinity,
    currency: currencyCode,
  };
  const minMoney = min(...bandPoints) ?? zero(currencyCode);

  const isEquityBand = Object.values(EquityBandName).includes(
    bandName as EquityBandName
  );

  // Accept rounding errors within one cent
  if (subtract(minMoney, compData).value > 0.001) {
    const exceededAmount = subtract(minMoney, compData);
    return getOutOfBandMessage(
      "below",
      exceededAmount,
      isEquityBand,
      equityUnitPrice,
      showCurrentEquityValue
    );
  }
  // Accept rounding errors within one cent
  if (subtract(compData, maxMoney).value > 0.001) {
    const exceededAmount = subtract(compData, maxMoney);
    return getOutOfBandMessage(
      "above",
      exceededAmount,
      isEquityBand,
      equityUnitPrice,
      showCurrentEquityValue
    );
  }

  return {
    outOfBand: false,
    icon: AlertingSuccess,
    description: `Within band.`,
  };
}

function getBandMessageForUnits(
  bandName: string,
  bandPoints: number[],
  compData: number,
  equityUnitPrice: Money,
  showCurrentEquityValue: boolean
): { icon: string; description: string; outOfBand: boolean } {
  const maxUnits = Math.max(...bandPoints);
  const minUnits = Math.max(Math.min(...bandPoints), 0);

  if (compData < minUnits) {
    const exceededAmount = minUnits - compData;
    return getOutOfBandMessage(
      "below",
      exceededAmount,
      true,
      equityUnitPrice,
      showCurrentEquityValue
    );
  }
  if (compData > maxUnits) {
    const exceededAmount = compData - maxUnits;

    return getOutOfBandMessage(
      "above",
      exceededAmount,
      true,
      equityUnitPrice,
      showCurrentEquityValue
    );
  }

  return {
    outOfBand: false,
    icon: AlertingSuccess,
    description: `Within band.`,
  };
}

function getOutOfBandMessage(
  direction: "above" | "below",
  exceededAmount: Money | number,
  isEquityBand: boolean,
  equityUnitPrice: Money,
  showCurrentEquityValue: boolean
) {
  if (typeof exceededAmount === "number") {
    const unitAmount = `${formatNumeral(exceededAmount)} units`;

    return {
      outOfBand: true,
      icon: AlertingError,
      description: `${unitAmount} ${direction} band.${
        // HACK (for Verkada): show exec approval text when above band (https://app.asana.com/0/0/1202303576910917/f)
        direction === "above" ? "\nEXEC APPROVAL REQUIRED." : ""
      }`,
    };
  }

  const equityExceededInUnits = divide(exceededAmount, equityUnitPrice.value);
  const cashAmount = getSimpleCashLabel(exceededAmount);
  const unitAmount = `${formatNumeral(equityExceededInUnits.value)} units`;

  const equityDescription = showCurrentEquityValue
    ? `${cashAmount} (${unitAmount})`
    : unitAmount;

  return {
    outOfBand: true,
    icon: AlertingError,
    description: `${
      isEquityBand ? equityDescription : cashAmount
    } ${direction} band.${
      // HACK (for Verkada): show exec approval text when above band (https://app.asana.com/0/0/1202303576910917/f)
      direction === "above" ? "\nEXEC APPROVAL REQUIRED." : ""
    }`,
  };
}

type LabelValueProps = {
  label: ReactNode | undefined;
  value: ReactNode | undefined;
  mt?: number;
  mb?: number;
  classes?: {
    label?: string;
    value?: string;
  };
};

export function LabelValue({
  label,
  value,
  mt,
  mb = 2,
  classes,
}: LabelValueProps): JSX.Element {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" mt={mt} mb={mb}>
      <Typography
        variant="overline"
        className={[styles.labelText, classes?.label].join(" ")}
      >
        {label}
      </Typography>
      <Typography variant="body1" className={classes?.value}>
        {value}
      </Typography>
    </Box>
  );
}

BandLabelValue.fragments = {
  position: gql`
    fragment BandLabelValue_position on Position {
      id
      name
      description
      level
      jobCodes
      type
      adjustedCashBands(
        currencyCode: $cashCurrencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        name
        bandPoints {
          name
          annualCashEquivalent
          hourlyCashEquivalent
          value {
            ... on CashValue {
              annualRate
              currencyCode
            }
          }
        }
      }
      adjustedEquityBands(
        currencyCode: $equityCurrencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        name
        bandPoints {
          name
          totalGrossValue
          totalUnits
          value {
            ... on CashValue {
              annualRate
              currencyCode
            }
            ... on UnitValue {
              unitValue
            }
            ... on PercentValue {
              decimalValue
            }
          }
        }
      }
    }
  `,
};
