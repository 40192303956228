import { gql } from "@apollo/client";
import { getPayCashEquivalent, isHourlyComp } from "@asmbl/shared/compensation";
import { moneyComparator } from "@asmbl/shared/money";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import {
  ButtonBase,
  Link,
  TableBody,
  TableCellProps,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { Column, useTable } from "react-table";
import {
  CashCompType,
  CompensationTimelineTable_cashCompensation as CashCompensation,
} from "../../__generated__/graphql";
import { CashBandName } from "../../constants";
import { AssembleTypography } from "../AssembleTypography";
import { useSort } from "../SortableTable";
import { CurrencyWithPercentCell } from "./CurrencyWithPercentCell";
import { DateCell } from "./DateCell";
import { TitleCell } from "./TitleCell";
import { WireTable } from "./WireTable/WireTable";
import { WireTableCell } from "./WireTable/WireTableCell";
import { WireTableHead } from "./WireTable/WireTableHead";
import { WireTableHeaderCell } from "./WireTable/WireTableHeaderCell";

type Props = {
  compChanges: CashCompensation[];
  className?: string;
};

type C = Column<CashCompensation> & {
  id: keyof CashCompensation;
  align?: TableCellProps["align"];
};

const useStyles = makeStyles(() => ({
  hidden: {
    display: "none",
    "@media print": {
      display: "table-row",
    },
  },
  showMore: {
    "@media print": { display: "none" },
  },
  headerCell: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
}));

export function CompensationTimelineTable({ compChanges, className }: Props) {
  const classes = useStyles();
  const columns: C[] = useMemo(
    () => [
      {
        id: "activeAt",
        Header: "Date",
        Cell: DateCell,
        accessor: "activeAt",
      },
      {
        id: "type",
        Header: "Type",
        Cell: TypeCell,
        accessor: "type",
      },
      {
        id: "annualCashEquivalent",
        Header: "Value",
        Cell: CurrencyWithPercentCell,
        accessor: (c) => getPayCashEquivalent(c),
        align: "right",
      },
      {
        id: "jobTitle",
        Header: "Title",
        Cell: TitleCell,
        accessor: "jobTitle",
      },
    ],
    []
  );

  const {
    sortedArray: sortedCashComp,
    orderBy,
    order,
    handleRequestSort,
  } = useSort(compChanges, "activeAt", "desc", {
    jobTitle: contramap(
      (c: CashCompensation) => c.jobTitle ?? "-",
      caseInsensitiveComparator
    ),
    annualCashEquivalent: contramap(
      (c: CashCompensation) => getPayCashEquivalent(c),
      moneyComparator
    ),
  });

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({ columns, data: sortedCashComp });

  // We limit the number of rows that we show at first, but don't bother with
  // a row that says "Show 1 More".
  const [showAll, setShowAll] = useState(false);
  const initialLimit = 4;
  const isShowingAll = showAll || rows.length <= initialLimit + 1;

  return (
    <WireTable {...getTableProps()} className={className}>
      <WireTableHead>
        <TableRow>
          {headers.map((header) => {
            const col = columns.find((c) => c.id === header.id) ?? columns[0];
            return (
              <WireTableHeaderCell<CashCompensation>
                align={col.align}
                cellTitle={header.render("Header")}
                orderByField={col.id}
                order={order}
                isSelected={orderBy === header.id}
                handleRequestSort={handleRequestSort}
                {...header.getHeaderProps()}
                key={header.getHeaderProps().key}
                width={col.id === "annualCashEquivalent" ? "20%" : "26%"}
                className={classes.headerCell}
              />
            );
          })}
        </TableRow>
      </WireTableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <TableRow
              data-cy={`comp-timeline-table-row-${index}`}
              {...row.getRowProps()}
              key={row.getRowProps().key}
              className={cx({
                [classes.hidden]: index >= initialLimit && !isShowingAll,
              })}
            >
              {row.cells.map((cell) => {
                const col =
                  columns.find((c) => c.id === cell.column.id) ?? columns[0];
                const cellProps = cell.getCellProps();

                return (
                  <WireTableCell
                    {...cellProps}
                    key={cellProps.key}
                    align={col.align}
                  >
                    {cell.render("Cell")}
                  </WireTableCell>
                );
              })}
            </TableRow>
          );
        })}
        {!isShowingAll && (
          <TableRow key="show-more" className={classes.showMore}>
            <WireTableCell colSpan={columns.length}>
              <Link component={ButtonBase} onClick={() => setShowAll(true)}>
                <AssembleTypography color="primary" variant="productSmallBold">
                  Show {rows.length - initialLimit} more
                </AssembleTypography>
              </Link>
            </WireTableCell>
          </TableRow>
        )}
      </TableBody>
    </WireTable>
  );
}

function TypeCell({
  value,
  row,
}: {
  value: CashCompType;
  row: {
    original: CashCompensation;
  };
}) {
  const { unit } = row.original;
  return <>{isHourlyComp(unit) ? "Hourly" : CashBandName[value]}</>;
}

CompensationTimelineTable.fragments = {
  cashCompensation: gql`
    fragment CompensationTimelineTable_cashCompensation on CashCompensation {
      activeAt
      employeeId
      type
      annualCashEquivalent
      hourlyCashEquivalent
      percentOfSalary
      jobTitle
      unit
    }
  `,
};
