import { collapseBands } from "./band";
import { CurrencyCode } from "./constants";
import { asPercentage } from "./math";
import { add, calculateCompaRatio, money, Money, zero } from "./money";
import { contramap } from "./sort";

enum CompUnit {
  CASH = "CASH",
  HOURLY_CASH = "HOURLY_CASH",
}

enum CompTypeEnum {
  HOURLY = "HOURLY",
  ANNUAL = "ANNUAL",
}

export type CashCompensation = {
  type: string; // CashCompComponent string
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  unit?: string;
};

interface BandPoint {
  name: string;
  value: number;
}

/**
 * Returns a function that, given an `option`, will return all sorts of useful
 * pay data: compa-ratio, band points, band-placement, and the total cash pay.
 */
export function getCompCalculator<T extends CashCompensation>(
  bands: { name: string; bandPoints: BandPoint[] }[] | undefined,
  activeCash: T[] | undefined,
  compStructure: { bandPointTypes: string[] }
): (option: "salary" | "variable" | "total") => {
  total: number | undefined;
  compaRatio: string | undefined;
  bandPoints: BandPoint[] | undefined;
  bandPlacement: "Above" | "Below" | "In band" | undefined;
} {
  return (option) => {
    const compSet = {
      salary: new Set(["SALARY"]),
      variable: new Set(["COMMISSION", "RECURRING_BONUS"]),
      total: new Set(["SALARY", "COMMISSION", "RECURRING_BONUS"]),
    }[option];

    const currency =
      activeCash?.at(0)?.annualCashEquivalent.currency ?? CurrencyCode.USD;

    const selectedCash = activeCash?.filter((c) => compSet.has(c.type));

    const total = selectedCash
      ?.map((c) => c.annualCashEquivalent)
      .reduce(add, zero(currency));

    const selectedBands =
      bands?.filter((c) =>
        // This accounts for converting "Recurring Bonus" into "RECURRING_BONUS"
        compSet.has(c.name.toUpperCase().split(" ").join("_"))
      ) ?? [];

    const bandPointsByName = collapseBands(selectedBands).sort(
      contramap(({ name }) => compStructure.bandPointTypes.indexOf(name))
    );

    const bandPointsByValue = bandPointsByName.sort(
      contramap(({ value }) => value)
    );

    const min = bandPointsByValue.at(0)?.value;
    const max = bandPointsByValue.at(-1)?.value;

    // Only calculate the band placement and compa-ratio if their salary and
    // band points are defined.
    const hasValidComp =
      total !== undefined && min !== undefined && max !== undefined;

    const compaRatio = !hasValidComp
      ? undefined
      : calculateCompaRatio(
          bandPointsByValue.map((bp) => money(bp.value, currency)),
          total
        ) ?? undefined;

    const compaRatioAsPercentage = asPercentage(compaRatio);

    const bandPlacement = !hasValidComp
      ? undefined
      : total.value > max
        ? "Above"
        : total.value < min
          ? "Below"
          : "In band";

    return {
      total: total?.value,
      compaRatio: compaRatioAsPercentage,
      bandPoints: bandPointsByName,
      bandPlacement,
    };
  };
}

export function isHourlyComp(type: string | undefined | null): boolean {
  return type === CompUnit.HOURLY_CASH;
}

export function isCashCompType(unitType: string | null | undefined): boolean {
  return (
    unitType === CompUnit.HOURLY_CASH ||
    unitType === CompUnit.CASH ||
    unitType === null
  );
}

/**
 * A helper for getting the Salary cash comp type.
 */
export function getSalaryCashComp<C extends { type: string }>(
  cashComp: C[] | null
) {
  if (!cashComp) return null;
  return cashComp.find((c) => c.type === "SALARY");
}

export function getPayCashEquivalent(comp: {
  unit?: string;
  unitType?: string | null;
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
}): Money {
  const unitType = comp.unitType ?? comp.unit;
  return isHourlyComp(unitType)
    ? comp?.hourlyCashEquivalent
    : comp?.annualCashEquivalent;
}

export function isHourlyType(type: string | undefined): boolean {
  return type === CompTypeEnum.HOURLY;
}
