import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyType } from "@asmbl/shared/compensation";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getSalaryBand } from "src/models/Band";
import { CondensedTableSalaryBandCell2_participant as Participant } from "../../../../__generated__/graphql";
import { CondensedBandVisualization } from "../../../../components/CompSlider/CondensedBandVisualization";
import { NullCompSlider } from "../../../../components/CompSlider/NullCompSlider";
import { isBandPointDefined } from "../../../../models/BandPoint";
import { getSalaryBandPlacement } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { BAND_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableSalaryBandCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const adjustedSalaryBand = getSalaryBand(employee.subject.adjustedCashBands);
  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);

  const position = employee.subject.activeEmployment?.position ?? null;

  const showHourly = Boolean(
    compStructure?.allowHourlyEmployees &&
      isEnabled(FeatureFlag.HourlyEmployees)
  );
  const isHourly = isHourlyType(position?.type) && showHourly;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {currentPay &&
      adjustedSalaryBand &&
      adjustedSalaryBand.bandPoints.some(isBandPointDefined) ? (
        <CondensedBandVisualization
          bandPoints={adjustedSalaryBand.bandPoints}
          value={
            isHourly
              ? currentPay.hourlyCashEquivalent
              : currentPay.annualCashEquivalent
          }
          position={position}
          outOfRangeStyle="circle"
          showHourly={isHourly}
        />
      ) : (
        <NullCompSlider variant="condensed" />
      )}
    </div>
  );
}

CondensedTableSalaryBandCell2.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap(
    (employee: Participant) =>
      getSalaryBandPlacement(
        employee.subject.activeCashCompensation,
        employee.subject.adjustedCashBands,
        currencies,
        defaultCurrency
      ) ?? 0
  );

CondensedTableSalaryBandCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryBandCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        activeEmployment {
          id
          position {
            id
            name
            type
            level
            ladder {
              id
              name
              department {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
};

CondensedTableSalaryBandCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_BAND
);
CondensedTableSalaryBandCell2.id = ColumnIds.CURRENT_BAND;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryBandCell2,
  Header: CondensedTableSalaryBandCell2.Header,
  id: CondensedTableSalaryBandCell2.id,
  width: BAND_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableSalaryBandCell2.column = column;
