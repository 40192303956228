import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyComp } from "@asmbl/shared/compensation";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CondensedTableSalaryPromoCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getPromotionSalary } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryPromoCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly = Boolean(
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees
  );
  const { defaultCurrencyCode } = useCurrencies();
  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const promoItem = recItems.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  );
  const isHourly = showHourly && isHourlyComp(promoItem?.unitType);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getPromotionSalary(
          recItems,
          currentPay?.annualCashEquivalent.currency ?? defaultCurrencyCode
        ),
        false,
        isHourly
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryPromoCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryPromoCell2_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }
    }
  `,
};

CondensedTableSalaryPromoCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_PROMO
);
CondensedTableSalaryPromoCell2.id = ColumnIds.SALARY_PROMO;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryPromoCell2,
  Header: CondensedTableSalaryPromoCell2.Header,
  id: CondensedTableSalaryPromoCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryPromoCell2.column = column;
CondensedTableSalaryPromoCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const salaryIncrease = getPromotionSalary(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(salaryIncrease.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(salaryIncrease, currentCurrency, defaultCurrency)
      .value;
  });
