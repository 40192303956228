import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  AddGuidanceRange,
  AddGuidanceRangeVariables,
  AddPerfRatingOption,
  AddPerfRatingOptionVariables,
  UpdateGuidanceRange,
  UpdateGuidanceRangeVariables,
  UpdateMatrixGuide,
  UpdateMatrixGuideVariables,
  UpdatePerfRatingOption,
  UpdatePerfRatingOptionVariables,
} from "src/__generated__/graphql";

const UPDATE_MATRIX_GUIDANCE = gql`
  mutation UpdateMatrixGuide($data: UpdateMatrixGuideInput!) {
    updateMatrixGuide(data: $data) {
      id
      percent
    }
  }
`;

export function useUpdateMatrixGuide(): (
  id: number,
  percent: number
) => Promise<unknown> {
  const [updateMatrixGuide] = useMutation<
    UpdateMatrixGuide,
    UpdateMatrixGuideVariables
  >(UPDATE_MATRIX_GUIDANCE);

  return useCallback(
    (id: number, percent: number) =>
      updateMatrixGuide({
        variables: { data: { id, percent } },
      }),
    [updateMatrixGuide]
  );
}

const ADD_PERF_RATING_OPTION = gql`
  mutation AddPerfRatingOption($data: AddGuidanceInput!) {
    addPerfRatingOption(data: $data) {
      id
      rank
    }
  }
`;

export function useAddPerfRatingOption(
  matrixId: number
): () => Promise<unknown> {
  const [addPerfRatingOption] = useMutation<
    AddPerfRatingOption,
    AddPerfRatingOptionVariables
  >(ADD_PERF_RATING_OPTION);

  return useCallback(
    () =>
      addPerfRatingOption({
        variables: { data: { matrixId } },
      }),
    [addPerfRatingOption, matrixId]
  );
}

const ADD_GUIDANCE_RANGE = gql`
  mutation AddGuidanceRange($data: AddGuidanceInput!) {
    addGuidanceRange(data: $data) {
      id
      rangeStart
    }
  }
`;

export function useAddGuidanceRange(matrixId: number): () => Promise<unknown> {
  const [addGuidanceRange] = useMutation<
    AddGuidanceRange,
    AddGuidanceRangeVariables
  >(ADD_GUIDANCE_RANGE);

  return useCallback(
    () =>
      addGuidanceRange({
        variables: { data: { matrixId } },
      }),
    [addGuidanceRange, matrixId]
  );
}

const UPDATE_PERF_RATING_OPTION = gql`
  mutation UpdatePerfRatingOption($data: UpdateMatrixPerfRatingOptionInput!) {
    updatePerfRatingOption(data: $data) {
      id
      name
    }
  }
`;

export function useUpdatePerfRatingOption(): (
  id: number,
  newName: string
) => Promise<unknown> {
  const [updatePerfRatingOption] = useMutation<
    UpdatePerfRatingOption,
    UpdatePerfRatingOptionVariables
  >(UPDATE_PERF_RATING_OPTION);

  return useCallback(
    (id: number, newName: string) =>
      updatePerfRatingOption({
        variables: { data: { id, name: newName } },
      }),
    [updatePerfRatingOption]
  );
}

const UPDATE_GUIDANCE_RANGE = gql`
  mutation UpdateGuidanceRange($data: UpdateMatrixRangeInput!) {
    updateGuidanceRange(data: $data) {
      id
      rangeStart
    }
  }
`;

export function useUpdateGuidanceRange(): (
  id: number,
  rangeStart: number
) => Promise<unknown> {
  const [updateGuidanceRange] = useMutation<
    UpdateGuidanceRange,
    UpdateGuidanceRangeVariables
  >(UPDATE_GUIDANCE_RANGE);

  return useCallback(
    (id: number, rangeStart: number) =>
      updateGuidanceRange({
        variables: { data: { id, rangeStart } },
      }),
    [updateGuidanceRange]
  );
}
