import { gql } from "@apollo/client";
import {
  getPayCashEquivalent,
  getSalaryCashComp,
  isHourlyComp,
} from "@asmbl/shared/compensation";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { CondensedTableNewSalaryCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { getNewPay } from "@asmbl/shared/employee";
import { getExchangedSalary } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableNewPayCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly = Boolean(
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees
  );
  const { currencies, defaultCurrency } = useCurrencies();
  const currSalary = getSalaryCashComp(employee.subject.activeCashCompensation);
  const newPay = getNewPay(
    {
      activeCashCompensation: employee.subject.activeCashCompensation,
      compRecommendation: employee.compRecommendation,
    },
    currencies,
    defaultCurrency
  );

  const isHourly = showHourly && isHourlyComp(newPay?.unitType);
  const newPayValue =
    newPay != null
      ? getPayCashEquivalent(newPay)
      : zero(currSalary?.annualCashEquivalent.currency ?? defaultCurrency.code);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(newPayValue, false, isHourly)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewPayCell.fragments = {
  participant: gql`
    fragment CondensedTableNewSalaryCell2_participant on CompCycleParticipant {
      subject {
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            value {
              ... on CashValue {
                annualRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
      }
    }
  `,
};

CondensedTableNewPayCell.Header = ColumnIdsToHeaders.get(ColumnIds.NEW_SALARY);
CondensedTableNewPayCell.id = ColumnIds.NEW_SALARY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewPayCell,
  Header: CondensedTableNewPayCell.Header,
  id: CondensedTableNewPayCell.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableNewPayCell.column = column;
CondensedTableNewPayCell.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap((employee: Participant) => {
    const newPay = getNewPay(
      {
        activeCashCompensation: employee.subject.activeCashCompensation,
        compRecommendation: employee.compRecommendation,
      },
      currencies,
      defaultCurrency
    );
    return newPay
      ? getExchangedSalary(
          defaultCurrency,
          { salary: newPay.annualCashEquivalent },
          currencies
        )?.value ?? 0
      : 0;
  });
